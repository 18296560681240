<script>
    import { gql } from '@apollo/client/core';
    import IBAN from 'iban';
    import bus from '@/bus.js';
    import { mapState } from 'vuex';
    import { Field } from 'vee-validate';

    export default {
        name: 'IbanInput',
        props: {
            fid: {type: String, default: null},
            modelValue: {type: String, default: ''},
            checkBank: {type: Boolean, default: true},
            notOrderableIbans: {type: Array, default: () => []},
            label: {type: String, default: ''},
            noLabel: {type: Boolean, default: false},
            rules: {type: String, default: () => ''},
            name: String,
            formRef: Object,
            narrow: Boolean,
        },
        components: {
            Field,
        },
        // VUE-3-MIGRATION-DIRT
        compatConfig: { COMPONENT_V_MODEL: false },
        data () {
            return {
                PLACEHOLDER: 'BE__ ____ ____ ____',
                detectedBank: '',
                content: this.modelValue,
                loading: false,
            };
        },
        computed: {
            displayLabel () { return this.label !== null ? this.label : this.$t('lbl-iban'); },
            placeholder () { return this.getPlaceholder(this.modelValue); },
            required () {
                return this.rules.includes('required');
            },
            computedRules () {
                // if required is set put in front to be the first validator, before format
                // to display "required" instead of "invalid format" when input is empty
                const rulesNoRequired = this.rules.replace('required', '');

                let rulesWithIban = `iban${rulesNoRequired ? '|' + rulesNoRequired : ''}`;

                if (this.required) {
                    return `required|${rulesWithIban}`;
                }

                return rulesWithIban;
            },
            ...mapState(['allBanks']),
        },
        watch: {
            modelValue (newVal) {
                this.content = IBAN.printFormat(newVal || '');
            },
        },
        mounted () {
            bus.on('validate', this.validate);
            this.content = IBAN.printFormat(this.modelValue || '');
        },
        beforeUnmount () {
            bus.off('validate', this.validate);
        },
        emits: ['update:modelValue', 'blur'],
        methods: {
            getPlaceholder (input) {
                var res = '';
                for (var i = 0; i < this.PLACEHOLDER.length; i++) {
                    if (i < input.length) {
                        res += ' ';
                    } else {
                        res += this.PLACEHOLDER[i];
                    }
                }
                return res;
            },
            validate () {
                return this.validateIban(this.content);
            },
            async validateIbanQuery (iban) {
                const { data } = await this.$apollo.query({
                    query: gql`query bankAccountValidation($iban: String!) {
                        bankAccountValidation(iban:$iban) {
                            formatValid
                            bankSupportsCoda
                            blacklisted
                            bankId
                        }
                    }`,
                    variables: {
                        iban: iban,
                    },
                });
                return data.bankAccountValidation;
            },
            async validateIban (iban) {
                if (!this.formRef) return;

                this.loading = true;

                this.formRef.setErrors({
                    [this.name]: null,
                });
                this.detectedBank = '';
                if (IBAN.isValid(iban)) {
                    if (!this.checkBank) {
                        this.loading = false;
                        return true;
                    } else {
                        const BAValidation = await this.validateIbanQuery(iban);
                        this.loading = false;

                        // TODO: it does not need to wait for the IbanQuery. However, if placed above it won't render the error
                        if (this.notOrderableIbans.includes(iban.replace(/ /g, ''))) {
                            this.loading = false;
                            this.formRef.setErrors({
                                [this.name]: 'err-iban-already-requested',
                            });
                            return false;
                        }

                        // check if iban is blacklisted
                        if (BAValidation.blacklisted) {
                            this.formRef.setErrors({
                                [this.name]: 'err-cannot-order',
                            });
                            return false;
                        }

                        // check if bank account supports CODA
                        if (!BAValidation.bankSupportsCoda) {
                            this.formRef.setErrors({
                                [this.name]: 'err-coda-not-supported',
                            });
                            return false;
                        }

                        // get the iban's corresponding bank
                        const bank = this.allBanks.find(b => b.id === BAValidation.bankId);

                        // if bank is know in our system, we show it to the user
                        if (bank) {
                            this.detectedBank = bank.name;
                            return true;
                        } else {
                            this.formRef.setErrors({
                                [this.name]: 'err-unknown-bank',
                            });
                            return false;
                        }
                    }
                } else {
                    this.loading = false;
                    return false;
                }
            },
            handleInput (e) {
                const iban = IBAN.printFormat(e.target.value);
                this.content = iban;
                this.$refs.input.value = iban;
            },
            exitField (e) {
                this.content = IBAN.printFormat(e.target.value);
                this.validateIban(this.content);
                this.$emit('blur');
            },
            selectAll (event) {
                setTimeout(function () {
                    event.target.select();
                }, 0);
            },
        },
    };
</script>
<template>
    <Field
        :name='name'
        :rules='computedRules'
        v-slot='{ field, errorMessage }'
        :value='content'
        ref='input'
        :validate-on-change='false'
        :validate-on-input='false'
        :validate-on-model-update='false'
    >
        <div>
            <label v-if='!noLabel' class='m-0 mb-2'>
                <span class='truncate'>
                    {{ displayLabel }}<span class='required' v-if='required'>*</span>
                </span>
            </label>
        </div>
        <div class='flex items-center w-full' :class='{"has-error": errorMessage, "narrow": narrow}'>
            <div class='h-16 w-full'>
                <input
                    v-bind='field'
                    class='h-12 w-full text-gray-700 border-2 border-solid border-gray-200 rounded px-3 leading-7 bg-transparent outline-none focus:border-blue-300'
                    :class='{ "detected-error-input": errorMessage, "detected-bank-input": detectedBank && !errorMessage}'
                    type='text'
                    autocomplete='off'
                    name='iban'
                    @input='handleInput'
                    @focus='selectAll'
                    @blur='exitField'
                    :id='fid'
                    :placeholder='placeholder'
                >
                <div v-if='loading' class='text-xs px-3'>
                    <i class='fa fa-circle-o-notch fa-spin text-grey-300'></i>
                </div>
                <div v-else-if='errorMessage && !errorMessage.includes("ibanBankAccountExists")' class='text-xs px-3 whitespace-nowrap overflow-visible detected-error'>
                    <span class='cb-bai-status-icon'>
                        <i class='fa fa-times-circle'></i>
                    </span>
                    <span class='cb-bai-status-bank'>{{ $t(errorMessage) }}</span>
                </div>
                <div v-else-if='detectedBank' class='text-xs px-3 detected-bank'>
                    <span class='cb-bai-status-icon'>
                        <i class='fa fa-check-circle'></i>
                    </span>
                    <span>{{ detectedBank }}</span>
                </div>
            </div>
        </div>
    </Field>
</template>

<style lang='scss' scoped>
/* ----------------- *\
   *    IBAN INPUT     *
  \* ----------------- */

.narrow {
  width: 320px;
  flex-direction: column;
  align-items: flex-start;
}

.cb-bai-status-icon {
  margin-right: 7px;
}

.required {
    color: $primary-color;
}

.detected-bank {
    color: #70c36b;
}

.detected-bank-input {
    border: 1px solid #70c36b;
}

.detected-error-input {
    border: 1px solid #f87171;
}

.detected-error {
    color: #f87171;
}
</style>
