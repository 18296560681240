<script setup>
import {ref} from 'vue';
import Wizard from '@/components/Wizard';
import WizardStep from '@/components/WizardStep';
import WizardButtons from '@/components/WizardButtons';
import WizardNextButton from '@/components/WizardNextButton';
import WizardPreviousButton from '@/components/WizardPreviousButton';
import WizardStepContent from '@/components/WizardStepContent';
import {Form} from 'vee-validate';
import FormInput from '@/components/FormInput';
import Popup from '@/clientcomponents/Popup.vue';
import FormRadio from '@/components/FormRadio.vue';
import validate from '@/validate';
import notify from '@/notify';
import {gql} from '@apollo/client/core';
import {t} from '@/i18n';
import {useMutation, useQuery} from '@vue/apollo-composable';
import Tooltip from '@/components/Tooltip.vue';
import router from '@/router';
import Icon from '@/components/Icon';
import CustomTitle from '@/components/Title';
import { useStore } from 'vuex';

const props = defineProps({
    isShown: {
        type: Boolean,
        required: true,
    },
    close: {
        type: Function,
        required: true,
    },
});

const store = useStore();

const newOrganizationInfoForm = ref();

const enterpriseNumber = ref('');
const enterpriseName = ref('');
const address = ref('');
const address2 = ref('');
const zip = ref('');
const city = ref('');
const representativeName = ref('');
const representativeEmailAddress = ref('');
const representativeFunction = ref('');
const invoicingEmailAddress = ref('');

const ongoingSubmitOrganizationInfo = ref(false);
const ongoingAddNewOrganization = ref(false);

async function submitOrganizationInfoStep (values, nextStep) {
    ongoingSubmitOrganizationInfo.value = true;

    const { onResult, onError } = useQuery(
        gql`query OrgEnterpriseNumExists($enterpriseNumber: String!) {
            organizationEnterpriseNumberExists(enterpriseNumber: $enterpriseNumber)
        }`,
        {
            enterpriseNumber: values.enterpriseNumber,
        },
    );
    onResult((result, context) => {
        if (result.data.organizationEnterpriseNumberExists) {
            newOrganizationInfoForm.value.setErrors({'enterpriseNumber': 'error duplicate'});
        } else {
            enterpriseNumber.value = values.enterpriseNumber;
            enterpriseName.value = values.enterpriseName;
            address.value = values.address;
            address2.value = values.address2;
            zip.value = values.zip;
            city.value = values.city;
            nextStep();
        }
    });
    onError((_, context) => {
        notify.error(t('err-unknown'));
    });

    ongoingSubmitOrganizationInfo.value = false;
}

function submitLegalRepresentativeStep (values, nextStep) {
    representativeName.value = values.representativeName;
    representativeEmailAddress.value = values.representativeEmailAddress;
    representativeFunction.value = values.representativeFunction;
    nextStep();
}

async function addNewOrganization (values) {
    invoicingEmailAddress.value = values.invoicingEmailAddress;

    ongoingAddNewOrganization.value = true;

    try {
        const { mutate: addNewOrgMutation } = await useMutation(gql`
            mutation AddNewOrganization($input: AddNewOrganizationInput) {
                addNewOrganization(input: $input) {
                    errors { code, detail, source { pointer } }
                    organization {
                        id
                    }
                }
            }
        `);
        const addNewOrgMutationResult = (await addNewOrgMutation({
            input: {
                enterpriseNumber: enterpriseNumber.value,
                hasBelgianVatNumber: true,
                enterpriseName: enterpriseName.value,
                address: address.value,
                address2: address2.value || '',
                zip: zip.value,
                city: city.value,
                representativeName: representativeName.value || `${store.state.user.firstName} ${store.state.user.lastName}`,
                representativeEmailAddress: representativeEmailAddress.value || store.state.user.username,
                representativeFunction: representativeFunction.value,
                invoicingEmailAddress: invoicingEmailAddress.value,
                isDirectCustomer: true, // For the moment, only DC can be created
            },
        })).data.addNewOrganization;

        if (addNewOrgMutationResult.errors?.length > 0) {
            validate.reportGQLFieldErrors(
                addNewOrgMutationResult.errors,
                newOrganizationInfoForm,
            );
        } else {
            notify.success(t('add-new-organization.success'));
            props.close();
            router.push(`/organization/${addNewOrgMutationResult.organization.id}/`);
        }
    } catch (error) {
        notify.error(t('err-unknown'));
    }

    ongoingAddNewOrganization.value = false;
}
</script>

<template>
    <Popup :show='props.isShown' :close='props.close'>
        <template #header>
            {{ $t('add-new-organization.popup.title') }}
        </template>
        <Wizard v-slot='{ previousStep, nextStep }' full-width>
            <WizardStep
                title='add-new-organization.steps.org-type.title'
                icon='BuildingOffice'
                class='flex flex-col'
            >
                <Form
                    tag='div'
                    v-slot='{ values }'
                    class='flex flex-col grow'
                >
                    <WizardStepContent bordered class='h-full flex flex-col grow'>
                        <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                            {{ $t('add-new-organization.steps.org-type.content-title') }}
                        </CustomTitle>
                        <Tooltip
                            faded
                            class='self-start'
                        >
                            <template #trigger>
                                <div class='flex items-center gap-1 text-grey-500'>
                                    <Icon name='InformationCircle' family='outline' />
                                    {{ $t('lbl-why-important') }}
                                </div>
                            </template>
                            <template #content>
                                {{ $t('add-new-organization.why-important.text') }}
                            </template>
                        </Tooltip>
                        <FormRadio
                            name='organizationType'
                            value='orgTypeAccountant'
                            id='orgTypeAccountant'
                            rules='required'
                            class='mb-2 mt-6'
                        >
                            {{ $t('add-new-organization.steps.org-type.radio-labels.accountant') }}
                        </FormRadio>
                        <FormRadio
                            name='organizationType'
                            value='orgTypeDirectCustomer'
                            id='orgTypeDirectCustomer'
                            rules='required'
                        >
                            {{ $t('add-new-organization.steps.org-type.radio-labels.direct-customer') }}
                        </FormRadio>
                        <div v-if='values.organizationType === "orgTypeAccountant"' class='flex gap-2 items-center rounded-md bg-grey-100 font-medium text-grey-700 mb-4 mt-3 px-4 py-3'>
                            <Icon name='InformationCircle' />
                            oh no
                        </div>
                        <WizardButtons>
                            <WizardNextButton
                                @click='nextStep'
                                id='org-type-next-step'
                                label='btn-continue'
                                :disabled='values.organizationType !== "orgTypeDirectCustomer"'
                            />
                        </WizardButtons>
                    </WizardStepContent>
                </Form>
            </WizardStep>
            <WizardStep
                title='add-new-organization.steps.org-info.title'
                icon='Pencil'
            >
                <WizardStepContent bordered class='h-full flex flex-col'>
                    <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                        {{ $t('add-new-organization.steps.org-info.content-title') }}
                    </CustomTitle>
                    <Form
                        tag='div'
                        v-slot='{ values, handleSubmit }'
                        class='mt-6'
                        ref='newOrganizationInfoForm'
                    >
                        <FormInput
                            name='enterpriseNumber'
                            :label='$t("lbl-enterprise-number")'
                            placeholder='0123456789'
                            rules='enterpriseNumberFormat|required'
                            edit
                            id='enterpriseNumber'
                            :formatter='str => validate.formatString(str, "DDDDDDDDDD")'
                            format-on-type
                        />
                        <FormInput
                            name='enterpriseName'
                            :label='$t("lbl-enterprise-name")'
                            :placeholder='$t("lbl-enterprise-name")'
                            rules='required|max:160'
                            edit
                            id='enterpriseName'
                            class='mt-3'
                        />
                        <FormInput
                            name='address'
                            :label='$t("lbl-address")'
                            :placeholder='$t("lbl-address-line-1")'
                            rules='required|max:100'
                            edit
                            id='address'
                            class='mt-3'
                        />
                        <FormInput
                            name='address2'
                            :placeholder='$t("lbl-address-line-2")'
                            edit
                            rules='max:100'
                            id='address2'
                            class='mt-3'
                        />
                        <FormInput
                            name='zip'
                            :label='$t("lbl-zip")'
                            :placeholder='$t("lbl-zip")'
                            rules='required|min:4|max:20'
                            edit
                            id='zip'
                            class='mt-3'
                        />
                        <FormInput
                            name='city'
                            :label='$t("lbl-city")'
                            :placeholder='$t("lbl-city")'
                            rules='required|max:50'
                            edit
                            id='city'
                            class='mt-3'
                        />
                        <WizardButtons>
                            <WizardPreviousButton
                                @click='previousStep'
                                label='add-new-organization.btn-previous'
                            />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { submitOrganizationInfoStep(values, nextStep)})'
                                id='org-info-next-step'
                                :disabled='ongoingSubmitOrganizationInfo'
                                label='btn-continue'
                            />
                        </WizardButtons>
                    </Form>
                </WizardStepContent>
            </WizardStep>
            <WizardStep
                title='add-new-organization.steps.legal-representative.title'
                icon='User'
            >
                <WizardStepContent bordered class='h-full flex flex-col grow'>
                    <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                        {{ $t('add-new-organization.steps.legal-representative.content-title') }}
                    </CustomTitle>
                    <Tooltip
                        faded
                        class='self-start'
                    >
                        <template #trigger>
                            <div class='flex items-center gap-1 text-grey-500'>
                                <Icon name='InformationCircle' family='outline' />
                                {{ $t('lbl-why-important') }}
                            </div>
                        </template>
                        <template #content>
                            {{ $t('add-new-organization.steps.legal-representative.why-important.text') }}
                        </template>
                    </Tooltip>
                    <Form
                        tag='div'
                        v-slot='{ values, handleSubmit }'
                        class='mt-6 grow flex flex-col'
                    >
                        <label class='m-0 p-0 font-bold font-grey-700'>
                            {{ $t('add-new-organization.steps.legal-representative.radio-buttons.label') }}
                        </label>

                        <div class='mt-3 flex gap-3 mb-6'>
                            <FormRadio
                                name='legalRepresentative'
                                value='no'
                                id='legalRepresentativeNo'
                                rules='required'
                            >
                                {{ $t('add-new-organization.steps.legal-representative.radio-buttons.no') }}
                            </FormRadio>
                            <FormRadio
                                name='legalRepresentative'
                                value='yes'
                                id='legalRepresentativeYes'
                                rules='required'
                            >
                                {{ $t('add-new-organization.steps.legal-representative.radio-buttons.yes') }}
                            </FormRadio>
                        </div>
                        <div v-if='values.legalRepresentative === "no"'>
                            <FormInput
                                name='representativeName'
                                :label='$t("lbl-representative-name")'
                                :placeholder='$t("lbl-representative-name")'
                                rules='composedName|required|max:100'
                                edit
                                id='representativeName'
                                clean
                                class='mb-3'
                            />
                            <FormInput
                                name='representativeEmailAddress'
                                :label='$t("lbl-representative-email")'
                                :placeholder='$t("lbl-representative-email")'
                                rules='email|required'
                                edit
                                id='representativeEmailAddress'
                                clean
                                class='mb-3'
                            />
                        </div>
                        <div v-if='values.legalRepresentative'>
                            <FormInput
                                name='representativeFunction'
                                :label='$t("lbl-representative-function")'
                                :placeholder='$t("lbl-representative-function")'
                                rules='required|max:128'
                                edit
                                id='representativeFunction'
                            />
                        </div>
                        <WizardButtons>
                            <WizardPreviousButton
                                @click='previousStep'
                                label='add-new-organization.btn-previous'
                            />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { submitLegalRepresentativeStep(values, nextStep)})'
                                id='legal-representative-next-step'
                                label='btn-continue'
                            />
                        </WizardButtons>
                    </Form>
                </WizardStepContent>
            </WizardStep>
            <WizardStep
                title='add-new-organization.steps.invoicing.title'
                icon='CreditCard'
            >
                <WizardStepContent bordered class='h-full flex flex-col grow'>
                    <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                        {{ $t('add-new-organization.steps.invoicing.content-title') }}
                    </CustomTitle>
                    <Form
                        tag='div'
                        v-slot='{ values, handleSubmit }'
                        class='mt-6 flex flex-col grow'
                    >
                        <FormInput
                            autocomplete='email'
                            :label='$t("lbl-invoicing-email-address")'
                            name='invoicingEmailAddress'
                            rules='email|required'
                            ref='emailInput'
                            mode='eager'
                            edit
                        />
                        <WizardButtons>
                            <WizardPreviousButton
                                @click='previousStep'
                                label='add-new-organization.btn-previous'
                            />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { addNewOrganization(values, nextStep)})'
                                id='invoicing-next-step'
                                :disabled='ongoingAddNewOrganization'
                                label='add-new-organization.btn-submit-add-new-org'
                            />
                        </WizardButtons>
                    </Form>
                </WizardStepContent>
            </WizardStep>
        </Wizard>
    </Popup>
</template>
