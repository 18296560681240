<script setup>
import { computed } from 'vue';
import Popup from '@/clientcomponents/Popup';
import {useStore} from 'vuex';
import SidenavItem from '@/components/sidenav/SidenavItem';
import SidenavGroup from '@/components/sidenav/SidenavGroup';
import Sidenav from '@/components/sidenav/Sidenav';
import UserSettingsChangePassword from '@/user/UserSettingsChangePassword';
import UserSettings2fa from '@/user/UserSettings2fa';
import UserSettingsInfo from '@/user/UserSettingsInfo';

const store = useStore();

const show = computed(() => store.state.userSettingsPopup.show);
const page = computed(() => store.state.userSettingsPopup.page);

function closePopup () {
    store.commit('closeUserSettingsPopup');
}
</script>

<template>
    <Popup :show='show' :close='closePopup' large small-paddings>
        <template #header>
            {{ $t('ttl-user-settings') }}
        </template>
        <div class='lg:flex items-stretch'>
            <Sidenav
                no-info
                narrow
                no-padding
            >
                <SidenavGroup background light full-height>
                    <SidenavItem
                        :text='$t("nav-user-settings-info")'
                        :active='page === "info"'
                        @click='store.commit("openUserSettingsInfoPopup")'
                    />
                    <SidenavItem
                        :text='$t("nav-change-password")'
                        :active='page === "changePassword"'
                        @click='store.commit("openUserSettingsChangePasswordPopup")'
                    />
                    <SidenavItem
                        :text='$t("nav-user-settings-2fa")'
                        :active='page === "2fa"'
                        @click='store.commit("openUserSettings2faPopup")'
                    />
                </SidenavGroup>
            </Sidenav>
            <div class='my-3 mt-0 lg:ml-6 w-full'>
                <UserSettingsInfo v-if='page === "info"' />
                <UserSettings2fa v-if='page === "2fa"' />
                <UserSettingsChangePassword v-if='page === "changePassword"' />
            </div>
        </div>
    </Popup>
</template>
