<script>
    export default {
        name: 'purchase-terms-fr',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>

        <p>Avec le service VOILA vous pouvez désormais recevoir de manière électronique les factures d'achat de vos clients, envoyées via différents canaux (Peppol, Zoomit, ...). Votre client ne doit donc plus vous transmettre ces factures d’achat.</p>

        <p>Vous pouvez commander le service via MyCodaBox par le menu 'Mandats & services > VOILA'. Ceci peut être fait pour l'ensemble de vos clients en même temps ou pour chaque client séparément, et ne nécessite que quelques clics !</p>

        <p>IMPORTANT: Prévenez vos clients qu'ils seront contactés par CodaBox pour accepter le service VOILA. Il s'agit d'une démarche importante en vue de maximaliser les chances d'inscription à ce service.</p>

        <p>Comment ce service fonctionne-t-il pour vos clients&nbsp;?</p>

        <ol>
            <li>Lorsque vous commandez le service pour vos clients, il leur sera demandé par e-mail d'accepter le service VOILA.</li>
            <li>Vos clients seront informés par CodaBox sur les étapes qui suivent l'acceptation du service et ce que cela implique pour eux.</li>
            <li>Une fois qu’un client aura accepté le service, CodaBox utilisera cette confirmation pour enregistrer votre client sur les différents réseaux de facturation électronique.</li>
            <li>Votre client n'aura plus besoin de vous transmettre les factures qu'il reçoit par ces canaux. Dès qu'un fournisseur envoie une facture, CodaBox la transmet à votre client via le mode de livraison choisi (portail client du comptable ou e-mail). Vous recevrez automatiquement les factures électroniques que CodaBox vous enverra par le mode de livraison que vous aurez choisi, comme pour la réception des CODA.</li>
        </ol>

        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Frais administratifs
        </h4>

        <p>Aucun frais administratif ne sera facturé.</p>

        <h4 class='cb-service-title'>
            Frais récurrents
        </h4>

        <p>Jusqu'à nouvel ordre, CodaBox appliquera une remise de 65% sur les prix standard indiqués. Si CodaBox réduit ou supprime cette remise, CodaBox vous en informera au moins trois mois à l'avance.</p>
        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 numéros d’entreprise</td>
                    <td>3,62 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 241 et 500 numéros d’entreprise</td>
                    <td>4,53 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 121 et 240 numéros d’entreprise</td>
                    <td>5,01 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 61 et 120 numéros d’entreprise</td>
                    <td>6,28 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 3 et 60 numéros d’entreprise</td>
                    <td>7,53 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 1 et 2 numéros d’entreprise</td>
                    <td>9,37 € /mois/numéro d’entreprise</td>
                </tr>
            </tbody>
        </table>

        <p>Pour déterminer le tarif correct, le nombre de services activés pour CODA et CARO est également pris en compte. Vous trouverez plus de détails dans les Conditions Générales, via le lien ci-dessous.</p>

        <p class='note'>
            <span class='fa fa-info-circle'></span>
            CodaBox ne peut pas déterminer quand le service CODA, CARO ou VOILA n'est plus nécessaire pour un certain client, une carte de crédit ou un compte bancaire, par exemple lorsqu'il n'y a plus de transactions sur un compte ou le compte est clôturé. Le service reste actif (et fait partie du calcul des frais récurrents) jusqu'à ce que vous le
            <a href='https://faq.codabox.com/fr/support/solutions/articles/75000087558-comment-d%C3%A9sactiver-un-service-coda-soda-voila-caro-' target='_blank'>désactiviez vous-même via MyCodaBox</a>.
        </p>

        <h3>Conditions Générales</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex VOILA (B3-C3-D).pdf' target='_blank'>Conditions générales du service</a>
        </p>
    </div>
</template>

<style lang='scss' scoped>
    .hl { color: rgb(153,204,0); }

    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    ol, tbody {
        color: $primary-color-dark;
    }
</style>
