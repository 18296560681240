<script setup>
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Popup from '@/clientcomponents/Popup.vue';
import Loader from '@/loader';
import { gql } from '@apollo/client/core';
import validate from '@/validate';
import notify from '@/notify';
import { ref } from 'vue';
import utils from '@/utils';
import { t } from '@/i18n.js';
import { useMutation } from '@vue/apollo-composable';
import { Form } from 'vee-validate';
import ClientAddCoda from '@/components/ClientAddCoda.vue';

const emits = defineEmits(['close']);

const orderCodaMandateForm = ref(null);
const bankAccountInputs = ref(null);
const saving = ref(false);

const props = defineProps({
    showOrderCodaMandatePopup: {
        type: Boolean,
        required: true,
    },
    clientV2: {
        type: Object,
        required: true,
    },
    currentEnvironment: {
        type: Object,
        required: true,
    },
});
function isSubmitOrderCodaMandateDisabled (formValues) {
    const hasExistingBASelected = formValues.existingBankAccounts && formValues.existingBankAccounts.some(ba => !!ba);
    const hasNewBA = formValues.newBankAccounts && formValues.newBankAccounts.length > 0;
    return saving.value || (!hasNewBA && !hasExistingBASelected);
}

async function submitOrderCodaMandateForm (values) {
    Loader.start();
    saving.value = true;

    const bankAccountInputsValid = await bankAccountInputs.value.validateBankAccounts();

    if (!bankAccountInputsValid) {
        Loader.stop();
        saving.value = false;
        return;
    }

    // Build the list of bank accounts to order based on new BA inputs and existing BA checkboxes
    let bankAccountsToOrder = [];

    if (values.newBankAccounts && values.newBankAccounts.length > 0) {
        values.newBankAccounts.forEach(ba => {
            if (ba) {
                bankAccountsToOrder.push({
                    'id': ba.replace(/\s+/g, ''),
                });
            }
        });
    }

    if (values.existingBankAccounts && values.existingBankAccounts.length > 0) {
        values.existingBankAccounts.forEach(ba => {
            if (ba) {
                bankAccountsToOrder.push({
                    'id': ba.replace(/\s+/g, ''),
                });
            }
        });
    }

    const { mutate: orderCodaMandate } = await useMutation(gql`
        mutation ($input: CodaOrderingInput!) {
                        orderCodaMandate(input: $input) {
                            data {
                                id
                                clientId
                                fiduciaryId
                                bankId
                                bank {
                                    id
                                    name
                                    slug
                                    abbreviation
                                    isSupported
                                    isTwikeySupported
                                    disableSendPdfMandate
                                    isCaroSupported
                                    isStopCodaSupported
                                    regexSavings
                                    isSignhereSupported
                                }
                                state
                                reasonCode
                                reasonNote
                                stateDate
                                bankAccounts{
                                    id
                                    type
                                    bankId
                                    state
                                    stateDate
                                    flowState
                                    bankMandateState
                                    iban
                                }
                                signHerePackage {
                                    packageStatus
                                    packageSignUrl
                                    packageRejectionReason
                                }
                                twikeyUrl
                                twikeyUrlCreatedAt
                                hasTeletransmissionPdf
                                reminders {
                                    date
                                    targetEmail
                                    type
                                }
                            },
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }
    `);

    const res = await orderCodaMandate({
        input: {
            fiduciaryId: props.currentEnvironment.id,
            clientId: props.clientV2.id,
            bankAccounts: bankAccountsToOrder,
        },
    });

    const response = res.data.orderCodaMandate;

    Loader.stop();
    saving.value = false;

    if (response.errors) {
        try {
            await catchOrderCodaMandateErrors(response.errors);
        } catch (err) {
            await validate.notifyGQLValidationErrors(err);
        }
        return;
    }
    if (response.data) {
        emits('close', response.data.map(m => m.id));
    }
    notify.success(t('p-order-coda-success'));
}

function catchOrderCodaMandateErrors (errors) {
    for (let i = 0; i < errors.length; i++) {
        let error = errors[i];
        if (error && error.source && error.source.pointer.match(/^\/data\/bankAccounts\/\d+\/id$/) && bankAccountInputs.value) {
            let inputIndex = error.source.pointer.substring(19, 20);
            // only show the first error returned for each bank account (which has the highest priority)
            if (orderCodaMandateForm.value.errors[`newBankAccounts[${inputIndex}]`] === undefined) {
                orderCodaMandateForm.value.setErrors({
                    [`newBankAccounts[${inputIndex}]`]: 'err-' + error.code,
                });
            }
            delete errors[i];
        }
    }

    if (utils.count(errors)) {
        return Promise.reject(errors);
    }

    return Promise.resolve();
}

function closeOrderCodaMandatePopup () {
    emits('close', null);
}
</script>

<template>
    <Form
        ref='orderCodaMandateForm'
        @submit='submitOrderCodaMandateForm'
        v-slot='{ values }'
    >
        <Popup
            :show='showOrderCodaMandatePopup'
            id='orderCodaMandatePopup'
            :close='closeOrderCodaMandatePopup'
        >
            <template #header>
                {{ $t('ttl-order-coda-step-1') }}
            </template>

            <ClientAddCoda
                :client-v2='clientV2'
                ref='bankAccountInputs'
                :form-ref='orderCodaMandateForm'
            />

            <template #buttons>
                <FriendlyButton
                    label='btn-cancel'
                    :action='closeOrderCodaMandatePopup'
                    square
                    extra-small
                    no-margin
                    secondary
                    class='mr-2'
                />
                <FriendlyButton
                    label='btn-save-mandate'
                    :disabled='isSubmitOrderCodaMandateDisabled(values)'
                    type='submit'
                    square
                    extra-small
                    no-margin
                />
            </template>
        </Popup>
    </Form>
</template>
