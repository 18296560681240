<script>
export default {
    props: {
        suppliers: {
            type: Array,
        },
    },
    methods: {
        formatDate (date) {
            let d = new Date(date);
            return `${String(d.getDate()).padStart(2, '0')} / ${String(d.getMonth() + 1).padStart(2, '0')} / ${d.getFullYear()}`;
        },
    },
};
</script>
<template>
    <div class='supplier-list'>
        <table>
            <thead>
                <tr>
                    <th>{{ $t('th-invoice-supplier-name') }}</th>
                    <th>{{ $t('th-invoice-received-date') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for='supplier of suppliers' :key='supplier.name'>
                    <td>{{ supplier.name }}</td>
                    <td>{{ formatDate(supplier.latestDocumentReceivedAt) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<style scoped lang='postcss'>
    .supplier-list table {
        background: whitesmoke;
        border-radius: 3px;
    }
    .supplier-list thead {
        border-bottom: solid 2px lightgrey;
    }
    .supplier-list th, .supplier-list td {
        padding: 6px 10px;
    }
</style>
