<script setup>
import {ref} from 'vue';
import FormStepper from '@/components/FormStepper.vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import notify from '../notify.js';
import { gql } from '@apollo/client/core';
import Translated from '@/components/Translated';
import FormToggle from '@/components/FormToggle.vue';
import { t } from '@/i18n';

const stepper = ref(null);
</script>
<template>
    <h1 class='mt-15'>
        {{ $t('ttl-manage-consent-stepper') }}
    </h1>
    <br>
    <br>
    <FormStepper
        :steps='[
            {
                key: "step-1",
                title: t("manage-consent-step1"),
            },
            {
                key: "step-2",
                title: t("manage-consent-step2"),
            },
            {
                key: "step-3",
                title: t("manage-consent-step3"),
            }
        ]'
        v-slot='{ currentStep, nextStep, previousStep }'
        ref='stepper'
    >
        <div class='text-center flex flex-col items-center justify-center mb-auto'>
            <template v-if='currentStep === "step-1"'>
                <div class=' flex flex-col mt-5 '>
                    <div class='border border-solid border-grey-200 p-6 inline-block rounded-md'>
                        <div class='inline-block'>
                            <div class='info-row'>
                                <span>{{ $t('ttl-connect-api-software-name') }}</span> {{ consent.softwareName }}
                            </div>
                            <div class='info-row'>
                                <span>{{ $t('ttl-connect-api-accountant-name') }}</span> {{ consent.accountantName }}
                            </div>
                        </div>
                    </div>
                    <div class='mt-6'>
                        {{ $t('lbl-connect-api-disclaimer') }}
                    </div>
                    <div class='mt-12 inline-block items-center'>
                        <FormToggle
                            :value='acceptTerms'
                            name='terms-toggle'
                            :edit='true'
                            @change='acceptTerms=!acceptTerms'
                        >
                            <Translated class='ml-3'>
                                <template #en>
                                    <p class='ml-3 mt-2'>
                                        I have read and accept the terms and conditions (<a href='https://www.codabox.com/fr/connect-gtc-fr/' target='_blank'>FR</a> - <a href='https://www.codabox.com/nl/connect-gtc-nl/' target='_blank'>NL</a>)
                                    </p>
                                </template>
                                <template #nl>
                                    <p class='ml-3 mt-2'>
                                        Ik heb de
                                        <a href='https://www.codabox.com/nl/connect-gtc-nl/' target='_blank'>algemene voorwaarden</a>
                                        gelezen en geaccepteerd.
                                    </p>
                                </template>
                                <template #fr>
                                    <p class='ml-3 mt-2'>
                                        J'ai lu et j'accepte les
                                        <a href='https://www.codabox.com/fr/connect-gtc-fr/' target='_blank'>conditions générales</a>
                                    </p>
                                </template>
                            </Translated>
                        </FormToggle>
                    </div>
                </div>
                <div class='mt-6 grid grid-cols-3 gap-10'>
                    <FriendlyButton
                        square
                        extra-small
                        no-margin
                        label='btn-connect-api-not-now'
                        fullwidth
                        secondary
                        :action='onDone_close'
                    />
                    <FriendlyButton
                        square
                        extra-small
                        no-margin
                        label='btn-connect-api-refuse'
                        @click='nextStep'
                        red
                        :action='setToDeny'
                        fullwidth
                    />
                    <FriendlyButton
                        square
                        extra-small
                        no-margin
                        label='btn-connect-api-confirm'
                        @click='nextStep'
                        :disabled='!acceptTerms'
                        :action='setToConfirm'
                        fullwidth
                    />
                </div>
            </template>
            <template v-if='currentStep === "step-2" && choice === "Deny"'>
                <h2 class='flex  mb-10 text-4xl mt-5 '>
                    {{ $t('Are-you-sure') }}
                </h2>
                <div class='mt-4 mb-10 flex justify-end'>
                    {{ $t('txt-confirm-choice-deny') }}
                    <br>
                    <br>
                    {{ $t('txt-confirm-choice-deny2') }}
                </div>
                <div class='mt-6 flex justify-end'>
                    <FriendlyButton
                        square
                        extra-small
                        no-margin
                        secondary
                        label='btn-connect-api-back'
                        class='mr-3'
                        @click='previousStep'
                    />
                    <FriendlyButton
                        label='btn-validate-connect-api-refuse'
                        no-margin
                        fullwidth
                        square
                        extra-small
                        @click='nextStep'
                        :action='refuse'
                        red
                    />
                </div>
            </template>
            <template v-if='currentStep === "step-2" && choice === "Confirm"'>
                <h2 class='flex mt-5 mb-10 text-4xl'>
                    {{ $t('Are-you-sure') }}
                </h2>
                <div class='mt-4 mb-10 flex justify-end' :style='{width: "500px"}'>
                    {{ $t('txt-confirm-choice-allow') }}
                </div>
                <div class='mt-6 flex justify-end'>
                    <FriendlyButton
                        square
                        extra-small
                        no-margin
                        secondary
                        label='btn-connect-api-back'
                        class='mr-3'
                        @click='previousStep'
                    />
                    <FriendlyButton
                        label='btn-validate-connect-api-confirm'
                        no-margin
                        fullwidth
                        square
                        extra-small
                        @click='nextStep'
                        :action='confirm'
                    />
                </div>
            </template>
            <template v-if='currentStep === "step-3"'>
                <div v-if='choice === "Confirm"' class='mt-5 mb-10 '>
                    {{ $t('txt-succ-allowed-consent') }}
                </div>
                <div v-if='choice === "Deny"' class='mt-5 mb-10 '>
                    {{ $t('txt-succ-denied-consent') }}
                </div>
                <div class='border border-solid border-grey-200 p-6 inline-block rounded-md'>
                    <div class='inline-block'>
                        <div class='info-row'>
                            <span>{{ $t('ttl-connect-api-software-name') }}</span> {{ consent.softwareName }}
                        </div>
                        <div class='info-row'>
                            <span>{{ $t('ttl-connect-api-accountant-name') }}</span> {{ consent.accountantName }}
                        </div>
                    </div>
                </div>
                <div class='mt-10 flex justify-end'>
                    <FriendlyButton
                        label='btn-connect-api-close'
                        no-margin
                        fullwidth
                        square
                        extra-small
                        @click='nextStep'
                        :action='onDone_close'
                    />
                </div>
            </template>
        </div>
    </FormStepper>
</template>

<script>
export default {
    props: {
        consentId: String,
        onDone: Function,
        onError: Function,
        onSuccess: Function,
    },
    data () {
        return {
            acceptTerms: false,
            consent: {},
            choice: '',
        };
    },
    watch: {
        consentId () {
            this.getConsentObject();
        },
    },
    created () {
        this.getConsentObject();
    },
    methods: {
        async getConsentObject () {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`query accountingSoftwareConsent($consentId: String!) {
                        accountingSoftwareConsent(consentId:$consentId) {
                            id
                            userId
                            softwareName
                            accountantId
                            accountantName
                            confirmationUri
                            state
                            created
                            modified
                            redirectUri
                            softwareId
                            accountantCompanyNumber
                        }
                    }`,
                    variables: {
                        consentId: this.consentId,
                    },
                });
                if (data.accountingSoftwareConsent.state !== 'unconfirmed') {
                    notify.success(this.$t('lbl-connect-api-incorrect-state'));
                    this.onSuccess(data.accountingSoftwareConsent);
                }
                this.consent = data.accountingSoftwareConsent;
            } catch (e) {
                notify.error(this.$t('err-connect-api-not-found'));
                this.onError(this.consent);
            }
        },
        async refuse () {
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: gql`mutation refuseAccountingSoftwareConsent($consentId: String!) {
                        refuseAccountingSoftwareConsent(consentId: $consentId) {
                            errors { code, detail, source { pointer } }
                        }
                    }`,
                    variables: {
                        consentId: this.consentId,
                    },
                });
                if (!data.refuseAccountingSoftwareConsent.errors) {
                    notify.success(this.$t('lbl-connect-api-refuse-success'));
                    if (this.onSuccess) {
                        this.onSuccess(this.consent);
                    }
                } else {
                    notify.error(this.$t('err-unknown'));
                }
            } catch (e) {
                notify.error(this.$t('err-connect-api-not-found'));
            }
        },
        async confirm () {
            try {
                const {data} = await this.$apollo.mutate({
                    mutation: gql`mutation acceptAccountingSoftwareConsent($consentId: String!) {
                        acceptAccountingSoftwareConsent(consentId: $consentId) {
                            errors { code, detail, source { pointer } }
                        }
                    }`,
                    variables: {
                        consentId: this.consentId,
                    },
                });
                if (!data.acceptAccountingSoftwareConsent.errors) {
                    notify.success(this.$t('lbl-connect-api-confirm-success'));
                    if (this.onSuccess) {
                        this.onSuccess(this.consent);
                    }
                } else {
                    notify.error(this.$t('err-unknown'));
                }
            } catch (e) {
                notify.error(this.$t('err-connect-api-not-found'));
            }
        },
        onDone_close () {
            this.onDone(this.consent);
        },
        setToConfirm () {
            this.choice = 'Confirm';
        },
        setToDeny () {
            this.choice = 'Deny';
        },
    },
};
</script>

<style lang="postcss" scoped>
.info-row {
    @apply mb-2;
}

.info-row:last-child {
    @apply mb-0;
}

.info-row span {
    @apply font-bold mr-2;
}
</style>
