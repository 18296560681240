<script>
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';

    export default {
        name: 'ArchiveButton',
        props: {
            'mandate': Object,
            'archive': Function,
            'label': {
                type: String,
                default: 'btn-archive',
            },
        },
        components: {
            FriendlyButton,
        },
        methods: {
            doArchive () {
                if (this.mandate.state !== 'archived') {
                    this.archive(this.mandate.id);
                }
            },
        },
    };
</script>
<template>
    <FriendlyButton
        :label='label'
        :action='doArchive'
        square
        extra-small
        micro
        no-margin
        :disabled='mandate.state === "archived"'
    />
</template>
