<script>
import { gql } from '@apollo/client/core';
import notify from '@/notify';
import Loader from '@/loader';
import utils from '../../utils';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { Form } from 'vee-validate';
import DatePicker from '@/components/DatePicker.vue';
import FormInput from '@/components/FormInput';
import FormCheckbox from '@/components/FormCheckbox';
import FormSelect from '@/components/FormSelect';
import Popup from '@/clientcomponents/Popup';

export default {
    props: {
        clientV2: {
            type: Object,
            required: true,
        },
        bankAccounts: {
            type: Array,
            required: true,
        },
    },
    components: {
        FormCheckbox,
        FormInput,
        Popup,
        FriendlyButton,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
        DatePicker,
        FormSelect,
    },
    data ()  {
        return {
            isPopupOpen: false,
            saving: false,
            advanced: false,
            firstDate: null,
            lastDate: null,
            codaRanges: [],
        };
    },
    computed: {
        activeBankAccounts () {
            return this.bankAccounts.filter((ba) => ba.state === 'active');
        },
        bankAccountsWithAvailableFiles () {
            // Build list of bankAccounts for the advanced form: BA with CODA files
            let baWithFiles = [];

            this.activeBankAccounts.forEach(ba => {
                if (this.hasAvailableFilesForIban(ba.iban)) {
                    baWithFiles.push({
                        iban: ba.iban,
                        bankName: ba.bankName,
                    });
                }
            });

            return baWithFiles;
        },
        hasAvailableFiles () {
            return this.codaRanges?.length > 0 && !!this.firstDate && !!this.lastDate;
        },
    },
    async mounted () {
        await this.openPopupAtLoad();
    },
    methods: {
        async openPopupAtLoad () {
            const queryParams = { ...this.$route.query };
            if (queryParams.openResendCodaPopup) {
                if (queryParams.openResendCodaPopup === true || queryParams.openResendCodaPopup === 'true') {
                    await this.openPopup();
                }

                // clean query param from the url (avoid reopen at refresh or if page is bookmarked)
                delete queryParams.openResendCodaPopup;
                this.$router.replace({ query: queryParams });
            }
        },
        async openPopup () {
            let ranges = await this.getCodaFileRanges(this.clientV2.id, this.activeBankAccounts.map(ba => { return ba.iban; }));
            this.firstDate = ranges.firstDate;
            this.lastDate = ranges.lastDate;
            this.codaRanges = ranges.codaRanges;

            this.isPopupOpen = true;
        },
        closePopup () {
            this.isPopupOpen = false;
        },
        advancedClick () {
            this.advanced = !this.advanced;
        },
        hasAvailableFilesForIban (iban) {
            const range = this.codaRanges.find(range => range.bankAccount === iban);
            return !!(range && range.firstDate && range.lastDate);
        },
        async resendCoda (input) {
            const { data } = await this.$apollo.mutate({
                mutation: gql`mutation resendCoda($input: ResendCodaInput) {
                    resendCoda(input: $input) {
                        errors { code, detail, source { pointer } }
                    }
                }`,
                variables: {
                    input: input,
                },
            });
            return data;
        },
        async submitResendCodaForm (values) {
            if (
                this.saving ||
                (this.advanced && !values.bankAccountsWithFiles?.some(ba => ba.selected))
            ) {
                return;
            }

            Loader.start();
            this.saving = true;

            let payload = {
                clientId: this.clientV2.id,
                fiduciaryId: this.clientV2.fiduciaryId,
            };

            if (!this.advanced) {
                payload.period = {
                    startDate: this.formatDate(values.period[0]),
                    endDate: this.formatDate(values.period[1]),
                };
            } else {
                payload.bankAccounts = [];
                values.bankAccountsWithFiles.forEach((ba, i) => {
                    if (ba.selected) {
                        if (ba.method === 'date') {
                            payload.bankAccounts.push({
                                iban: this.bankAccountsWithAvailableFiles[i].iban,
                                period: {
                                    startDate: this.formatDate(ba.period.date[0]),
                                    endDate: this.formatDate(ba.period.date[1]),
                                },
                            });
                        } else {
                            payload.bankAccounts.push({
                                iban: this.bankAccountsWithAvailableFiles[i].iban,
                                index: {
                                    year: ba.index.year,
                                    start: ba.index.start,
                                    end: ba.index.end,
                                },
                            });
                        }
                    }
                });
            }

            try {
                const data = await this.resendCoda(payload);
                if (data.resendCoda.errors) {
                    this.showApiErrors(data.resendCoda.errors);
                    Loader.stop();
                    this.saving = false;
                    return;
                }
                notify.success(this.$t('p-resend-coda-success'));
            } catch (err) {
                notify.error(this.$t('p-resend-coda-error'));
            }

            Loader.stop();
            this.saving = false;
            this.closePopup();
        },
        showApiErrors (errors) {
            if (!this.advanced) {
                let noCodaFileErrors = errors.filter((err) => err.detail.includes('No CodaFile in range'));
                if (noCodaFileErrors.length > 0) {
                    this.$refs.resendCodaForm.setErrors({
                        period: [this.$t('err-no-coda-in-range')],
                    });
                    notify.error(this.$t('err-no-coda-in-range'));
                } else {
                    notify.error(this.$t('p-resend-coda-error'));
                }
            } else {
                let noCodaFileErrors = errors.filter((err) => err.detail.includes('No CodaFile in range'));
                if (noCodaFileErrors.length > 0) {
                    for (const err of noCodaFileErrors) {
                        // Get index in the form from the error pointer in format "/data/bankAccounts/INDEX/iban",
                        // the pointer index is build based on list provided by MyCodaBox, so we can trust the order
                        const bankAccountIndex = err.source.pointer.split('/')[3];
                        // Set error using the index in form
                        this.$refs.resendCodaForm.setErrors({
                            [`bankAccountsWithFiles[${bankAccountIndex}].period.date`]: [this.$t('err-no-coda-in-range')],
                        });
                    }
                    notify.error(this.$t('err-no-coda-in-range'));
                } else {
                    notify.error(this.$t('p-resend-coda-error'));
                }
            }
        },
        async getCodaFileRanges (clientId, bankAccounts) {
            const { data } = await this.$apollo.query({
                query: gql`query codaFileRange($clientId: String, $bankAccounts: [String]!) {
                    codaFileRange(clientId: $clientId, bankAccounts:$bankAccounts) {
                        bankAccount
                        firstDate
                        lastDate
                    }
                }`,
                variables: {
                    clientId: clientId,
                    bankAccounts: bankAccounts,
                },
            });
            if (data.codaFileRange && data.codaFileRange.length !== 0) {
                // Format stringDate to Date object to make following date manipulations easier
                const codaFileRanges = data.codaFileRange.map(codaFileRange => {
                    return {
                        ...codaFileRange,
                        ...{
                            firstDate: new Date(codaFileRange.firstDate),
                            lastDate: new Date(codaFileRange.lastDate),
                        },
                    };
                });
                return {
                    firstDate: new Date(Math.min(...codaFileRanges.map(codaFileRange => codaFileRange.firstDate.setHours(0)))),
                    lastDate: new Date(Math.max(...codaFileRanges.map(codaFileRange => codaFileRange.lastDate.setHours(0)))),
                    codaRanges: codaFileRanges,
                };
            } else {
                return {
                    firstDate: null,
                    lastDate: null,
                    codaRanges: [],
                };
            }
        },
        formatDate (value) {
            return utils.date2strYYYYMMDD(value);
        },
        getCodaFileRangeBankAccount (iban) {
            // Note: if getCodaFileRangeBankAccount for a bank account it means this bank accounts is display in advanced data
            // And because only bank accounts with codaRanges are display in advanced data, we can be sure there is always a codaRanges here
            const codaRange = this.codaRanges.find(range => range.bankAccount === iban);
            return this.formatDate(codaRange.firstDate) + ' / ' + this.formatDate(codaRange.lastDate);
        },
        fillPeriod () {
            if (!this.advanced) {
                this.$refs.resendCodaForm.setFieldValue('period', [this.firstDate, this.lastDate]);
            }
        },
        advancedRequiredRule (bankAccountItem) {
            return this.advanced && bankAccountItem && bankAccountItem.selected ? 'required' : '';
        },
        bankAccountYearValues () {
            const minYear = new Date(this.firstDate).getFullYear();
            const maxYear = new Date(this.lastDate).getFullYear();

            let values = [];
            for (let i = minYear; i <= maxYear; i++) {
                values.push({
                    label: i,
                    value: i,
                });
            }

            return values;
        },
        bankAccountPeriodMinMaxDates (iban) {
            let min = this.firstDate;
            let max = this.lastDate;

            const range = this.codaRanges.find(range => range.bankAccount === iban);
            if (range && range.firstDate && range.lastDate) {
                min = range.firstDate;
                max = range.lastDate;
            }

            return {
                min,
                max,
            };
        },
        fillBankAccountPeriod (bankAccount, listIndex) {
            this.$refs.resendCodaForm.setFieldValue(`bankAccountsWithFiles[${listIndex}].method`, 'date');
            const firstDate = this.codaRanges.find(range => range.bankAccount === bankAccount.iban).firstDate;
            const lastDate = this.codaRanges.find(range => range.bankAccount === bankAccount.iban).lastDate;
            this.$refs.resendCodaForm.setFieldValue(`bankAccountsWithFiles[${listIndex}].period.date`, [firstDate, lastDate]);
            this.$refs.resendCodaForm.setFieldValue(`bankAccountsWithFiles[${listIndex}].selected`, true);
        },
        selectBankAccountItem (currentSelectedValue, itemIndex) {
            if (!currentSelectedValue) {
                this.$refs.resendCodaForm.setFieldValue(`bankAccountsWithFiles[${itemIndex}].selected`, true);
            }
        },
    },
};
</script>

<template>
    <div>
        <FriendlyButton
            label='btn-resend-coda'
            :action='openPopup'
            id='resend-coda-button'
            extra-small
            square
            no-margin
            :disabled='activeBankAccounts.length === 0'
            symbol='refresh'
        />
        <Form
            ref='resendCodaForm'
            tag='div'
            id='resend-coda-form'
            @submit='submitResendCodaForm'
            v-slot='{values}'
        >
            <Popup
                :show='isPopupOpen'
                :close='closePopup'
                id='resend-coda-popup'
                extra-large
            >
                <template #header>
                    {{ $t('ttl-popup-resend-coda') }}
                </template>

                <div>
                    <header class='mb-6'>
                        <h2 class='text-lg'>
                            {{ $t('lbl-resend-coda-simple-date') }}
                        </h2>
                    </header>
                    <div>
                        <DatePicker
                            id='period-datepicker'
                            name='period'
                            :placeholder='$t("placeholder-input-date")'
                            edit
                            range
                            :disabled='advanced || !hasAvailableFiles'
                            :rules='`${advanced ? "" : "required"}`'
                            :min-date='firstDate'
                            :max-date='lastDate'
                            :start-date='lastDate'
                        />
                        <a class='mt-3 flex' :class='{ "disabled": advanced }' v-if='hasAvailableFiles' @click='fillPeriod()'>
                            {{ $t('lbl-coda-range', { firstDate : formatDate(firstDate), lastDate: formatDate(lastDate)} ) }}
                        </a>
                        <p class='mt-3' v-else>
                            {{ $t('lbl-coda-range-platform-transfer-no-files') }}
                        </p>
                    </div>
                </div>
                <div class='mt-6' v-if='hasAvailableFiles'>
                    <FriendlyButton
                        label='btn-resend-coda-advanced'
                        :action='advancedClick'
                        :symbol='advanced ? "chevron-down" : "chevron-right"'
                        extra-small
                        square
                    />
                    <div class='mt-6 border-b border-gray-200' v-if='advanced'>
                        <table class='min-w-full divide-y divide-gray-200'>
                            <thead>
                                <tr>
                                    <th scope='col' class='relative pl-6 py-3'>
                                        <span class='sr-only'>Select</span>
                                    </th>
                                    <th scope='col' class='px-6 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                        {{ $t('th-resend-coda-bank-accounts') }}
                                    </th>
                                    <th scope='col' class='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                        {{ $t('th-resend-coda-methods') }}
                                    </th>
                                    <th scope='col' class='relative px-6 py-3'>
                                        <span class='sr-only'>Edit</span>
                                    </th>
                                    <th scope='col' class='px-6 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                        <p>{{ $t('th-resend-coda-range') }}</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class='bg-white divide-y divide-gray-200' id='bank-accounts-with-files-list'>
                                <tr
                                    v-for='(ba, i) in bankAccountsWithAvailableFiles'
                                    :key='i'
                                    :class='`${i % 2 ? "": "bg-grey-50 rounded-md"}`'
                                    ref='`advancedRows`'
                                    :id='`bank-account-with-files-${i}`'
                                >
                                    <td class='pl-6'>
                                        <FormCheckbox :name='`bankAccountsWithFiles[${i}].selected`' edit />
                                    </td>
                                    <td class='px-6 py-4 whitespace-nowrap'>
                                        {{ ba.iban }} ({{ ba.bankName }})
                                    </td>
                                    <td class='px-6 py-4 whitespace-nowrap'>
                                        <FormSelect
                                            :name='`bankAccountsWithFiles[${i}].method`'
                                            edit
                                            :rules='`${advancedRequiredRule(values.bankAccountsWithFiles?.[i])}`'
                                            :options='[
                                                {
                                                    label: $t("lbl-resend-coda-method-date"),
                                                    value: "date",
                                                },
                                                {
                                                    label: $t("lbl-resend-coda-method-index"),
                                                    value: "index",
                                                }
                                            ]'
                                            value='date'
                                            class='min-w-52'
                                        />
                                    </td>
                                    <td class='px-6 py-4 whitespace-nowrap w-full'>
                                        <div v-if='values.bankAccountsWithFiles?.[i].method === "date"'>
                                            <DatePicker
                                                :id='`bank-account-period-datepicker-${i}`'
                                                :name='`bankAccountsWithFiles[${i}].period.date`'
                                                :placeholder='$t("placeholder-input-date")'
                                                edit
                                                range
                                                :rules='`${advancedRequiredRule(values.bankAccountsWithFiles?.[i])}`'
                                                :min-date='bankAccountPeriodMinMaxDates(ba.iban).min'
                                                :max-date='bankAccountPeriodMinMaxDates(ba.iban).max'
                                                :start-date='bankAccountPeriodMinMaxDates(ba.iban).max'
                                                @change='e => {
                                                    selectBankAccountItem(values.bankAccountsWithFiles?.[i].selected, i);
                                                }'
                                            />
                                        </div>
                                        <div v-else class='flex'>
                                            <FormSelect
                                                :name='`bankAccountsWithFiles[${i}].index.year`'
                                                :placeholder='$t("placeholder-input-year")'
                                                edit
                                                :rules='`${advancedRequiredRule(values.bankAccountsWithFiles?.[i])}|integer`'
                                                :options='bankAccountYearValues()'
                                                @change='e => {
                                                    selectBankAccountItem(values.bankAccountsWithFiles?.[i].selected, i);
                                                }'
                                            />
                                            <FormInput
                                                :name='`bankAccountsWithFiles[${i}].index.start`'
                                                :placeholder='$t("placeholder-resend-coda-index-from")'
                                                edit
                                                :rules='`${advancedRequiredRule(values.bankAccountsWithFiles?.[i])}|integer|min_value:0|max_value:999`'
                                                @input='e => {
                                                    selectBankAccountItem(values.bankAccountsWithFiles?.[i].selected, i);
                                                }'
                                            />
                                            <FormInput
                                                :name='`bankAccountsWithFiles[${i}].index.end`'
                                                :placeholder='$t("placeholder-resend-coda-index-to")'
                                                edit
                                                :rules='`${advancedRequiredRule(values.bankAccountsWithFiles?.[i])}|integer|min_value:${values.bankAccountsWithFiles?.[i].index?.start ? values.bankAccountsWithFiles[i].index.start : "0"}|max_value:999`'
                                                @input='e => {
                                                    selectBankAccountItem(values.bankAccountsWithFiles?.[i].selected, i);
                                                }'
                                            />
                                        </div>
                                    </td>
                                    <td class='px-6 py-4 whitespace-nowrap w-full'>
                                        <a @click='fillBankAccountPeriod(ba, i)'> {{ getCodaFileRangeBankAccount(ba.iban) }} </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <template #buttons>
                    <div class='flex'>
                        <FriendlyButton
                            label='btn-cancel'
                            class='mr-3 ml-auto'
                            :action='closePopup'
                            symbol='times'
                            small
                            no-margin
                            secondary
                        />
                        <FriendlyButton
                            label='btn-resend-coda'
                            type='submit'
                            small
                            no-margin
                            symbol='check'
                            :disabled='saving || !hasAvailableFiles || (advanced && !values.bankAccountsWithFiles?.some(ba => ba.selected))'
                            id='submit-resend-coda-form'
                        />
                    </div>
                </template>
            </Popup>
        </Form>
    </div>
</template>

<style lang='postcss'>
    a {
      cursor: pointer;
    }

    a.disabled {
        cursor: not-allowed;
        text-decoration: none;
    }
</style>
