import utils from './utils.js';
import notify from './notify.js';
import { defineRule, configure } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email, min, max, length, integer, min_value, max_value } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import en from '@vee-validate/i18n/dist/locale/en.json';
import _ from 'lodash';
import IBAN from 'iban';
import parsePhoneNumber from 'libphonenumber-js/max';
import { PASSWORD_RULES, EXACT_EMAIL_REGEX } from './constants/common';
import { t, simpleLocale, loadSavedLocale }  from './i18n.js';
import { stdnum } from 'stdnum';

// Register Vee-Validate global rules
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('length', length);
defineRule('integer', integer);
defineRule('min_value', min_value);
defineRule('max_value', max_value);

// Custom messages
const customMessages = {
    en: {
        messages: {
            length: 'This must contain 0:{length} characters.',
            min: 'This must be at least 0:{length} characters.',
            max: 'This cannot be greater than 0:{length} characters.',
            min_value: 'Number must be bigger than 0:{min}.',
            max_value: 'Number must be smaller than 0:{max}.',
            integer: 'This must be a number (without decimals).',
            email: 'Invalid Email.',
            required: 'This field is required.',
        },
    },
    fr: {
        messages: {
            length: 'Ce champ doit contenir 0:{length} caractères.',
            min: 'Ce champ doit être long d’au moins 0:{length} caractères.',
            max: 'Ce champ ne peut dépasser 0:{length} caractères.',
            min_value: 'Le nombre doit être plus grand que 0:{min}.',
            max_value: 'Le nombre doit être plus petit que 0:{max}.',
            integer: 'Ce champ doit contenir un nombre entier.',
            email: 'Email invalide.',
            required: 'Ce champ est requis.',
        },
    },
    nl: {
        messages: {
            length: 'Dit moet 0:{length} karakters bevatten.',
            min: 'Dit moet minimum 0:{length} karakters bevatten.',
            max: 'Dit kan niet groter zijn dan 0:{length} karakters.',
            min_value: 'Het nummer kan niet kleiner zijn dan 0:{min}.',
            max_value: 'Het nummer kan niet groter zijn dan 0:{max}.',
            integer: 'Dit moet een geheel getal zijn.',
            email: 'E-mail ongeldig.',
            required: 'Dit veld is verplicht.',
        },
    },
};

// Merge custom messages with the default ones
en.messages = {
    ...en.messages,
    ...customMessages.en.messages,
};

fr.messages = {
    ...fr.messages,
    ...customMessages.fr.messages,
};

nl.messages = {
    ...nl.messages,
    ...customMessages.nl.messages,
};

configure({
    generateMessage: localize({
        en,
        fr,
        nl,
    }),
});

setLocale(simpleLocale(loadSavedLocale()));

defineRule('iban', value => {
    return !value || IBAN.isValid(value) ? true : t('err-invalid-iban');
});

defineRule('enterpriseNumberFormat', value => {
    const {
        isValid,
    } = stdnum.BE.vat.validate(value);
    return isValid ? true : t('val-bad-format');
});

defineRule('TOTPFormat', value => {
    const FORMAT = 'DDDDDD';
    return value && value.length !== FORMAT.length ? t('val-bad-format') : true;
});

defineRule('phone', value => {
    const number = value || '';
    const phoneNumber = parsePhoneNumber(number, 'BE');
    return phoneNumber && value.charAt(0) === '+' && phoneNumber.country === 'BE' && phoneNumber.isValid() ? true : t('val-bad-phone-format');
});

defineRule('exactEmail', value => {
    const str = value || '';
    if (str === '') {
        return true;
    } else if (EXACT_EMAIL_REGEX.test(str)) {
        return true;
    } else {
        return t('val-bad-exact-email');
    }
});

function isComposedName (str) {
    const regex = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ'’-]+(?:\s[A-Za-zÀ-ÖØ-öø-ÿ'’-]+)+$/);
    return regex.test(str);
}

defineRule('composedName', str => {
    return isComposedName(str) ? true : t('val-bad-composed-name-format');
});

defineRule('name', value => {
    const regex = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ'’-\s]+$/);
    return regex.test(value) ? true : t('val-bad-name-format');
});

defineRule('ccsClientReference', value => {
    return value && value.length === 10 && luhnChecksum(value) === 0 && (value.charAt(0) === '6' || value.charAt(0) === '7') ? true : t('val-bad-ccs-client-reference-format');
});

function checkPasswordRule (rgx, password) {
    const regex = new RegExp(rgx);
    return regex.test(password);
}

function isPasswordStrong (password = '') {
    for (const rule of PASSWORD_RULES) {
        if (password.length > 0 && !checkPasswordRule(rule.regex, password)) {
            return false;
        }
    }

    return true;
}

defineRule('password', value => {
    return isPasswordStrong(value) ? true : t('val-password-not-strong');
});

/* luhn_checksum
 * Implement the Luhn algorithm to calculate the Luhn check digit.
 * Return the check digit.
 */
function luhnChecksum (code) {
    var len = code.length;
    var parity = len % 2;
    var sum = 0;
    for (var i = len - 1; i >= 0; i--) {
        var d = parseInt(code.charAt(i));
        if (i % 2 === parity) { d *= 2; }
        if (d > 9) { d -= 9; }
        sum += d;
    }
    return sum % 10;
}

// formatters
function formatString (input, pattern) {
    let formatted = '';

    for (let i = 0; i < pattern.length; null) {
        const currentChar = input[0];
        if (!currentChar) {
            return formatted;
        }

        switch (pattern[i]) {
            case 'D':
                if (currentChar.match(/[0-9]/)) {
                    formatted += currentChar;
                    i++;
                }
                input = input.slice(1);
                break;
            case 'A':
                if (currentChar.match(/[a-zA-Z]/)) {
                    formatted += currentChar;
                    i++;
                }
                input = input.slice(1);
                break;
            case ' ':
                formatted += ' ';
                i++;
                if (currentChar === ' ') {
                    input = input.slice(1);
                }
                break;
            default:
                break;
        }
    }

    return formatted;
}

// Translations for API error messages
const translations = {
    'This field may not be blank.': 'err-required-constraint',
    'This field must be unique.': 'err-unique-constraint',
    'Invalid enterprise number.': 'err-invalid-enteprise-num',
    'Cannot sign or refuse purchase invoice mandate if one is already signed': 'err-cannot-sign-or-refuse-purchase-invoice-mandate',
    'Unable to login with provided credentials.': 'err-invalid-credentials',
    'Enter a valid email address.': 'err-email-not-valid',
    'This email is not known.': 'err-email-not-known',
    'This email is not known or already activated': 'err-email-not-known-or-activated',
    'The fields fiduciary_id, client_code must make a unique set.': 'err-client-code-not-unique',
    'Invalid user id or user doesn\'t exist.': 'err-invalid-user-id',
    'Invalid token for given user.': 'err-invalid-token-for-user',
    'Invalid password.': 'err-invalid-password',
    'Authentication credentials were not provided.': 'err-session-expired',
    'non_field_errors': 'lbl-non-field-errors',
    'email': 'lbl-email',
    'uid': 'lbl-uid',
    'current_password': 'lbl-current-password',
    'current_purchase_invoice_mandate_state': 'err-mandate-state',
    'enterprise_num': 'lbl-enterprise-number',
    'legal_entity.enterprise_num': 'lbl-enterprise-number',
    'Cannot sign or refuse purchase invoice mandate if the client\'s fiduciary has not agreed with purchase invoice mandate': 'err-fiduciary-agreement',
    'username': 'lbl-username',
    'This user does not have the mandate creation permission': 'err-mandate-creation-perm',
    'err-unknown': 'err-unknown',
    'err-server-fail': 'err-server-fail',
    'err-internet-problem': 'err-internet-problem',
    'Client already exists in the legal entity.': 'err-unique-constraint',
    'The legal entity of the fiduciary client has no enterprise number': 'err-missing-uen',
};

/*
 * Returns a translated error message,
 * using translations found in extraTranslations in priority.
 *
 *   translateMsg('Invalid user') -> "Utilisateur Invalide"
 *
 * Can also translate an array of error messages.
 *
 *   translateMsg(['Invalid user', 'err-unknown'])
 *   -> ['Utilisateur Invalide', 'Erreur Inconnue']
 */

function translateMsg (msg, extraTranslations) {
    if (Array.isArray(msg)) {
        return msg.map(m => translateMsg(m, extraTranslations));
    } else {
        extraTranslations = extraTranslations || {};
        return extraTranslations[msg] ? t(extraTranslations[msg]) : t(translations[msg] || msg);
    }
}

/*
 * Catch field validation errors from Api error object.
 *
 * Api.rpc('POST','test/',{'foo':'bar'})
 *     .then((res) => { ... })
 *     .catch((err) => {
 *          return catchFieldErrors(err, this.errors, this.fields);
 *      });
 *
 * This necessitates access to Vue components this.errors & this.fields
 * which are available in all Vue components methods
 *
 * Only field errors corresponding to validated fields will be caught.
 *
 * If there are uncaught fields, or other errors, this returns a
 * failed promise with the uncaught errors.
 */

function catchFieldErrors (err, observer, extraTranslations, fieldMap) {
    if (!err || err.msg === '[vee-validate]: Validation Failed') {
        return Promise.resolve();
    } else if (err.error !== 'api' || err.status !== 400) {
        return Promise.reject(err);
    } else {
        fieldMap = fieldMap || {};
        var uncaught = {};
        if (Array.isArray(err.body.errors)) {
            let observerErrors = {};
            err.body.errors.forEach(error => {
                const field = error.source.pointer.replace('/data/', '');
                observerErrors[field] = translateMsg(error.detail, extraTranslations);
            });
            observer.setErrors(observerErrors);
        } else {
            var errors = utils.flatten(err.body);
            for (var field in errors) {
                var _field = fieldMap[field] || field;
                if (observer.fields[_field]) {
                    let observerError = {};
                    if (errors[field] instanceof Array) {
                        observerError[_field] = errors[field].map(msg => translateMsg(msg, extraTranslations));
                    } else {
                        observerError[_field] = translateMsg(errors[field], extraTranslations);
                    }
                    observer.setErrors(observerError);
                } else {
                    uncaught[field] = errors[field];
                }
            }
        }

        if (utils.count(uncaught)) {
            return Promise.reject({
                error: err.error,
                status: err.status,
                body: uncaught,
            });
        } else {
            return Promise.resolve();
        }
    }
}

function reportGQLFieldErrors (errors, form, extraTranslations) {
      /**
     * Notify validation errors from GraphQL related to a field.
     *
     * Errors format from backend, defined in CodaBox guidelines:
     * {
     *   "errors": [
     *     {
     *       "code": "required",
     *       "title": "string",
     *       "detail": "string",
     *       "source": {
     *         "pointer": "/data"
     *       }
     *     }
     *   ]
     * }
     * where source/pointer is optional and is the field name if it's an error related to a field.
     *
     * Rule to detect error related to a field:
     *  - there is a source/pointer
     *  - AND the source/pointer is not "/data"
     */
    let observerErrors = {};
    errors.forEach(error => {
        if (error.source) {
            const field = error.source.pointer.replace('/data/', '').replace('/', '');
            observerErrors[field] = translateMsg(error.detail, extraTranslations);
        }
    });
    form.setErrors(observerErrors);
}

function notifyGQLValidationErrors (errors, extraTranslations) {
    /**
     * Notify validation errors from GraphQL not related to a field.
     *
     * Errors format from backend, defined in CodaBox guidelines:
     * {
     *   "errors": [
     *     {
     *       "code": "required",
     *       "title": "string",
     *       "detail": "string",
     *       "source": {
     *         "pointer": "/data"
     *       }
     *     }
     *   ]
     * }
     * where source/pointer is optional and is the field name if it's an error related to a field.
     *
     * Rule to detect error not related to a field:
     *  - there is no source
     *  - OR there is no source/pointer
     *  - OR the source/pointer is "/data"
     */
    let messages = [];

    errors.forEach(error => {
        if (!error.source || (error.source && !error.source.pointer) || (error.source && error.source.pointer && error.source.pointer.includes('/data'))) {
            messages.push(translateMsg(error.code, extraTranslations));
        }
    });
    if (messages.length !== 0) {
        notify.error(messages.join(' \n'));
    }
}

function notifyGraphQLErrors (e, extraTranslations) {
    let code;
    if (e.length) {
        e.forEach(error => {
            code = error.extensions.code;
            notify.error(translateMsg(code, extraTranslations));
        });
    } else {
        code = e.extensions.code;
        notify.error(translateMsg(code, extraTranslations));
    }
    if (!code) {
        notify.error(translateMsg('err-unknown'));
    }
}

function getBodyMessages (err, extraTranslations) {
    let msgs = [];
    if (err.body && err.body.errors) {
        err.body.errors.forEach(error => {
            msgs.push(translateMsg(error.detail, extraTranslations));
        });
    } else {
        const errors = utils.flatten(err.body);
        for (var field in errors) {
            if (typeof errors[field] === 'string') {
                errors[field] = [errors[field]];
            }
            if (field === 'non_field_errors') {
                msgs.push(translateMsg(errors[field], extraTranslations).join(', '));
            } else {
                msgs.push(translateMsg(field, extraTranslations) + ': ' + translateMsg(errors[field], extraTranslations).join(', '));
            }
        }
    }
    return msgs.join(' \n');
}

/* Display notifications for all 400 Api errors in rpc error object */
function notifyApiErrors (err, extraTranslations) {
    if (err.error !== 'api' || err.status !== 400) {
        return Promise.reject(err);
    } else {
        const msgs = getBodyMessages(err, extraTranslations);
        notify.error(msgs);
        return Promise.resolve();
    }
}

/* Display notifications for all 403 errors in rpc error object */
function notifyAuthErrors (err, extraTranslations) {
    if (err.error !== 'api' || !(err.status === 403 || err.status === 401)) {
        return Promise.reject(err);
    } else {
        var errors = utils.flatten(err.body);
        if (errors.detail) {
            notify.error(translateMsg(errors.detail, extraTranslations));
        } else {
            notify.error(t('err-session-expired'));
        }
        return Promise.resolve();
    }
}

/* Display notifications for all Server errors in rpc error object */
function notifyServerErrors (err, extraTranslations) {
    if (err.error !== 'server') {
        return Promise.reject(err);
    } else {
        const msgs = getBodyMessages(err, extraTranslations);
        if (msgs.length > 0) {
            notify.error(msgs);
        } else {
            notify.error(t('err-server-fail'));
        }
        return Promise.resolve();
    }
}

/* Display notifications for all Network errors in rpc error object */
function notifyNetworkErrors (err, extraTranslations) {
    if (err.error !== 'network') {
        return Promise.reject(err);
    } else {
        notify.error(t('err-server-fail'));
        return Promise.resolve();
    }
}

/* Display notifications for all errors in rpc error object */
function notifyErrors (err, extraTranslations) {
    return notifyApiErrors(err, extraTranslations)
        .catch(err => notifyAuthErrors(err, extraTranslations))
        .catch(err => notifyServerErrors(err, extraTranslations))
        .catch(err => notifyNetworkErrors(err, extraTranslations));
}

/* Ignore some errors */
function ignoreFieldsErrors (err, errorsToIgnore) {
    if (!err || err.msg === '[vee-validate]: Validation Failed') {
        return Promise.resolve();
    } else if (err.error !== 'api' || err.status !== 400) {
        return Promise.reject(err);
    } else {
        errorsToIgnore = errorsToIgnore || [];
        const errors = err.body;
        errorsToIgnore.forEach(errorToIgnore => {
            if (_.has(errors, errorToIgnore)) {
                _.unset(errors, errorToIgnore);
            }
        });
        if (utils.count(errors)) {
            return Promise.reject({
                error: err.error,
                status: err.status,
                body: errors,
            });
        } else {
            return Promise.resolve();
        }
    }
}

export default {
    catchFieldErrors,
    reportGQLFieldErrors,
    notifyGraphQLErrors,
    notifyGQLValidationErrors,
    notifyApiErrors,
    notifyAuthErrors,
    notifyServerErrors,
    notifyNetworkErrors,
    ignoreFieldsErrors,
    notifyErrors,
    translateMsg,
    isComposedName,
    isPasswordStrong,
    checkPasswordRule,
    formatString,
};
