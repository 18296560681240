<script>
import CodaStateReason from './CodaStateReason';
import CodaState from '@/components/CodaState';
import Dropdown from '@/components/DropdownV2';
import CodaDownloadButtons from '@/components/CodaDownloadButtons.vue';
import InsightInfo from '@/components/InsightInfo.vue';
import { mapState } from 'vuex';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { dateFormat } from '@/filters';
import Tooltip from '@/components/Tooltip.vue';
import CodaArchiveWarningModal from '@/components/CodaArchiveWarningModal.vue';
import CodaSignatureButtons from '@/components/CodaSignatureButtons.vue';

export default {
    name: 'CodaCard',
    props: {
        accountant: { type: Object, required: true },
        mandate: Object,
        selected: Boolean,
        noShadow: {
            type: Boolean,
            default: false,
        },
        animated: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CodaArchiveWarningModal,
        CodaSignatureButtons,
        Tooltip,
        CodaStateReason,
        CodaState,
        Dropdown,
        CodaDownloadButtons,
        InsightInfo,
        FriendlyButton,
    },
    data () {
        return {
            displayedBankAccount: -1,
            // firstLook because in some cases we want to open by default some bank accounts accordeon
            // but then it should work as usual
            firstLook: true,
            bankAccountToArchive: null,
            showArchiveMandatePopup: false,
        };
    },
    computed: {
        isBelfiusOrBnp () {
            return this.mandate.bankId === 'GEBABEBB' || this.mandate.bankId === 'GKCCBEBB';
        },
        hasDownloadState () {
            const canDownloadStates = ['available-online', 'prepared', 'sent-client', 'problem'];
            return this.mandate.bank.name && canDownloadStates.includes(this.mandate.state);
        },
        filteredReminders () {
            let filteredReminders = [];
            if (this.mandate.reminders) {
                if (this.mandate.state === 'sent-client') {
                    filteredReminders = this.mandate.reminders.filter(e => e.type === 'coda-mandate-signature');
                } else if (
                        ['bank-procedure', 'sent-hq', 'active'].includes(this.mandate.state) &&
                        this.mandate.bankAccounts.some(ba => ba.flowState === 'pending-activation-by-client')
                    ) {
                    filteredReminders = this.mandate.reminders.filter(e => e.type === 'bank-account-activation');
                }
            }
            return filteredReminders;
        },
        hasAnyKbcCbcIncompleteBankProcedureProblem () {
            return this.currentClientProblems.some(p => p.type === 'kbcCbcIncompleteBankProcedure');
        },
        ...mapState(['currentClientProblems']),
    },
    emits: ['getCodaMandatesEvent'],
    methods: {
        dateFormat: dateFormat,
        stateLabel (flowState, state = null, mandateState = null) {
            // we do not want to display a state in these cases
            if ((
                mandateState === 'problem'
                ) || (
                flowState === 'draft' && mandateState === 'signed'
            )) {
                return null;
            } else {
                return 'lbl-ba-coda-state-' + flowState;
            }
        },
        typeLabel (type) {
            return (type === 'current' || type === 'savings') ? this.$t(`lbl-account-${type}`) : '';
        },
        displayBankAccount (index, account) {
            if (
                this.displayedBankAccount !== index &&
                !this.displayedBankAccountAtFirstLook(account.state)
            ) {
                this.displayedBankAccount = index;
            } else {
                this.displayedBankAccount = -1;
            }
            this.firstLook = false;
        },
        displayedBankAccountAtFirstLook (state) {
            return state === 'pending' && this.firstLook;
        },
        accountClass (state) { return 'cb-bank-state-' + state; },
        getCodaMandates () {
            this.$emit('getCodaMandatesEvent');
        },
        canArchiveBankaccount (mandate, account) {
            return (
                ['signed', 'bank-procedure', 'sent-hq', 'active'].includes(mandate.state) &&
                account.state !== 'archived' &&
                mandate.bankAccounts.length - mandate.bankAccounts.filter(account => account.state === 'archived').length > 1
            );
        },
        kbcCbcIncompleteBankProcedureHighlight (account) {
            return (
                this.hasAnyKbcCbcIncompleteBankProcedureProblem &&
                ['KREDBEBB', 'CREGBEBB'].includes(account.bankId) &&
                account.state === 'pending' &&
                account.bankMandateState === 'pending'
            );
        },
    },
};
</script>

<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class='coda-card' :class='{"no-shadow": noShadow, animated}'>
        <span v-if='selected' class='selected'>{{ $t("pill-manage") }}</span>
        <CodaArchiveWarningModal
            v-if='showArchiveMandatePopup'
            :mandate='mandate'
            :bank-account-to-archive='bankAccountToArchive'
            :is-direct-customer='accountant.isDirectCustomer'
            :close='() => {
                showArchiveMandatePopup = false;
                bankAccountToArchive = null;
            }'
            @get-coda-mandates='getCodaMandates'
        />
        <div class='head'>
            <div>{{ mandate.bank.name }}</div>
            <div class='head__actions' v-if='mandate.state !== "archived"'>
                <Dropdown
                    direction='right'
                    :name='$t("lbl-coda-action")'
                    button-small
                    dark
                    icon='sort'
                    menu-extra-classes='overflow-y-visible'
                >
                    <FriendlyButton
                        label='btn-archive'
                        :action='() => showArchiveMandatePopup = true'
                        square
                        extra-small
                        micro
                        no-margin
                        :disabled='mandate.state === "archived"'
                        :id='`codaCardArchiveCodaMandateButton_${mandate.id}`'
                    />
                    <div v-if='hasDownloadState' class='mt-2'>
                        <CodaDownloadButtons :mandate='mandate' />
                    </div>
                </Dropdown>
            </div>
        </div>
        <div class='status-n-date'>
            <CodaState :mandate='mandate' class='status' variant='labelized' />
            <div>{{ $t('coda-date-on') }} <span class='grey-date'>{{ dateFormat(mandate.stateDate) }}</span></div>
        </div>
        <div class='reason'>
            <span class='fa fa-info-circle'></span>
            <CodaStateReason
                :accountant='accountant'
                :mandate='mandate'
                multiline
                wide
            />
        </div>
        <div v-if='mandate.hasRedeliveryRequest' class='reason'>
            <span class='fa fa-info-circle'></span>
            <p class='pending-info'>
                {{ $t('lbl-pending-redelivery-request') }}
            </p>
        </div>
        <div class='reminders' v-if='filteredReminders.length'>
            <span class='bg-grey-300 px-4 py-1 rounded-full text-white text-md mb-2 inline-block'>
                <span v-if='filteredReminders.length === 1'>
                    {{ $t('lgnd-coda-num-reminder') }}
                </span>
                <span v-else>
                    {{ $t('lgnd-coda-num-reminders', { num: filteredReminders.length }) }}
                </span>
            </span>
            <div class='reminders-body'>
                <div v-for='(r, i) in filteredReminders' :key='i' class='m-6'>
                    {{ r.date }}
                    <span class='px-3 text-grey-200'>|</span>
                    <span v-if='r.targetEmail'>
                        {{ $t('lgnd-coda-reminder-sent', { target_email: r.targetEmail }) }}
                    </span>
                    <span v-else>
                        {{ $t('lgnd-coda-reminder-sent-post') }}
                    </span>
                </div>
            </div>
        </div>
        <div>
            <div v-for='(account, index) in mandate.bankAccounts' :key='index'>
                <InsightInfo
                    :has-problem='kbcCbcIncompleteBankProcedureHighlight(account)'
                    class='w-full'
                >
                    <div class='bank py-1 px-3' :class='{"bg-grey-200 rounded": !(index % 2)}'>
                        <div class='bank-head' @click='displayBankAccount(index, account)'>
                            <div class='bank-controls__open mr-3'>
                                <span class='arrow' :class='`${displayedBankAccount === index ? "arrow--top" : ""}`'></span>
                            </div>
                            <div class='flex items-center'>
                                <span :class='`${accountClass(account.state)}`'>{{ account.iban }}</span>
                                <span class='bg-grey-250 text-grey-500 py-1 px-2 rounded-md uppercase tracking-wide font-bold text-xs ml-3'>
                                    {{ typeLabel(account.type) }}
                                </span>
                            </div>
                            <div class='ml-auto bank-controls'>
                                <div class='head__actions' v-if='canArchiveBankaccount(mandate, account)'>
                                    <tooltip faded min-width-max no-wrap class='ml-2 emailVerificationStateTooltip'>
                                        <template #trigger>
                                            <FriendlyButton
                                                :action='() => {
                                                    bankAccountToArchive = account;
                                                    showArchiveMandatePopup = true;
                                                }'
                                                square
                                                extra-small
                                                no-margin
                                                symbol='archive'
                                                no-text
                                                secondary-grey
                                                :disabled='account.state === "archived"'
                                            />
                                        </template>
                                        <template #content>
                                            {{ $t('archive-ba-tooltip') }}
                                        </template>
                                    </tooltip>
                                </div>
                            </div>
                        </div>
                        <div
                            class='bank-body mb-3'
                            v-show='displayedBankAccount === index || displayedBankAccountAtFirstLook(account.state)'
                        >
                            <div
                                class='mr-6'
                                v-if='stateLabel(account.flowState, account.state, mandate.state)'
                                v-html='$t(stateLabel(account.flowState, account.state, mandate.state), {bank_name: mandate.bank.name})'
                            >
                            </div>
                            <div class='bank-date ml-auto' v-if='account.stateDate && stateLabel(account.flowState, account.state, mandate.state)'>
                                {{ $t('coda-date-on') }} <span class='grey-date'>{{ dateFormat(account.stateDate) }}</span>
                            </div>
                            <div
                                v-if='!stateLabel(account.flowState, account.state, mandate.state)'
                                class='no-bank-state'
                                v-html='$t("lbl-ba-coda-state-no-bank-account")'
                            ></div>
                        </div>
                    </div>
                </InsightInfo>
            </div>
        </div>
        <div class='footer mt-6'>
            <div class='footer__downloads'>
                <CodaSignatureButtons
                    :mandate='mandate'
                    @refresh-coda-mandates='getCodaMandates'
                    v-if='mandate.state !== "archived"'
                />
            </div>
        </div>
    </div>
</template>

<style lang='scss' scoped>
.coda-card {
    @apply bg-grey-50;
    width: 100%;
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 30px;
    position: relative;

    &.no-shadow {
        box-shadow: none;
    }

    &.animated {
        .selected {
            animation: appear .3s $ease-in-out forwards;
        }
        &.no-shadow {
            animation: no-shadow .3s $ease-in forwards;
        }
    }
}

.selected {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    font-size: .9em;
    padding: 3px 10px 1px;
    border-radius: 20px;
    transform: translate(-10%, -50%);
    background: $warning-color;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translate(-10%, -100%);
    }
    100% {
        opacity: 1;
        transform: translate(-10%, -50%);
    }
}

@keyframes no-shadow {
    0% {
        box-shadow: 0 0 30px $color-grey;
    }
    100% {
        box-shadow: none;
    }
}

.arrow {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    background: url('~@/assets/arrow.svg') center center;
    background-size: 100% 100%;
    display: inline-block;

    &--right {
        transform: rotate(-90deg);
    }

    &--top {
        transform: rotate(-180deg);
    }
}

.menu-dot {
    width: 15px;
    height: 15px;
    background: url('~@/assets/menu_dot.svg') center center;
    background-size: 100% 100%;
    display: inline-block;
}

.head,
.status-n-date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.head__actions {
    display: flex;
    align-items: center;
    cursor: pointer;

    .menu-dot {
        margin-left: 10px;
    }
}

.reason {
    display: flex;
    margin-bottom: 15px;
    .fa {
        color: $color-grey-dark;
        margin-top: 4px;
        margin-right: 15px;
    }
}

.bank-head,
.bank-body {
    display: flex;
}

.bank-head {
    cursor: pointer;
    align-items: center;

    &__type-icon {
        margin-left: 10px;
    }
}

.bank-body {
    font-size: 1rem;
    margin-top: 5px;
}

.bank-date {
    min-width: 100px;
}

.bank-controls {
    @apply flex items-center;
}

.bank-controls__open {
    @apply rounded-md flex items-center justify-center h-10 w-6;
}

.head__actions {
    padding: 5px 0 5px 5px;
}

.grey-date {
    color: $color-grey-medium;
    margin-left: 5px;
}

.footer {
    display: flex;

    &__action {
        margin-left: auto;
    }
}

.footer__downloads {
    margin-left: auto;
}
// bank states

.cb-bank-state-new,
.cb-bank-state-pending,
.cb-bank-state-draft,
.cb-bank-state-testing,
.cb-bank-state-blocked,
.cb-bank-state-archived,
.cb-tooltip,
.cb-bank-state-non-zoomit-bank {
    color: $primary-color-dark;
}

.cb-bank-state-active,
.cb-bank-state-registered {
    color: $validation-color;
}

.cb-bank-state-registration-failed {
    color: $warning-color;
}

.cb-bank-state-problem {
    color: $error-color;
}

.cb-bank-state-archived,
.cb-bank-state-non-zoomit-bank {
    text-decoration: line-through;
}

.no-bank-state {
    color: $color-grey-medium;
    font-style: italic;
}
</style>

<style lang="postcss" scoped>
.reminders {
    @apply cursor-pointer mb-3 relative text-xl;
}

.reminders:hover .reminders-body {
    @apply opacity-100 translate-y-0 pointer-events-auto;
}

.reminders-body {
    @apply opacity-0 absolute bg-white shadow-lg rounded-md left-0 z-10 transform -translate-y-2 border border-solid border-grey-200;
    @apply pointer-events-none whitespace-nowrap min-w-full;
    @apply transition duration-100 ease-in-out;
}
.pending-info {
    font-size: 1.2rem;
}

</style>
