import utils from './utils.js';

function dateFormat (value) {
    var date = new Date(value);
    return date.getFullYear() + '-' +
           utils.lzpad(2, date.getMonth() + 1) + '-' +
           utils.lzpad(2, date.getDate());
}

export {
    dateFormat,
};
