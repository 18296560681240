<script setup>
import FormInput from '@/components/FormInput.vue';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

defineProps({
    number: String,
    name: {
        type: String,
        default: () => 'phoneNumber',
    },
    label: {
        type: String,
        default: () => '',
    },
    info: {
        type: String,
    },
    rules: {
        type: String,
        default: () => '',
    },
    edit: Boolean,
});

function phoneFormatterWithoutSpace (string) {
    if (!string) {
        return string;
    }
    const phoneNumber = parsePhoneNumberFromString(string, 'BE');
    if (phoneNumber) {
        return phoneNumber.format('E.164');
    } else {
        return string;
    }
}

function phoneFormatterWithSpaces (string) {
    if (!string) {
        return string;
    }
    const phoneNumber = parsePhoneNumberFromString(string, 'BE');
    if (phoneNumber) {
        return phoneNumber.formatInternational();
    } else {
        return string;
    }
}
</script>

<template>
    <FormInput
        :name='name'
        :label='label'
        :info='info'
        :rules='`phone|${rules}`'
        :edit='edit'
        :value='number'
        :formatter='phoneFormatterWithoutSpace'
        :display-formatter='phoneFormatterWithSpaces'
        placeholder='+ _ _ _ _ _ _ _ _ _ _ _'
    />
</template>
