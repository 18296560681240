<script setup>

import VueApexCharts from 'vue3-apexcharts';
import ContentBox from '@/components/ContentBox.vue';
import CustomTitle from '@/components/Title.vue';
import { useI18n } from 'vue-i18n';
import Tooltip from '@/components/Tooltip.vue';
const { t } = useI18n();

const emits = defineEmits(['clicked']);
const props = defineProps({
    products: {
        type: Array,
        required: true,
    },
    series: {
        type: Array,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
    showHeader: {
        type: Boolean,
        default: true,
    },
});

const chartOptions = {
    chart: {
        type: 'bar',
        height: 300,
        stacked: true,
        toolbar: { show: false },
    },
    plotOptions: {
        bar: {
            horizontal: true,
        },
    },
    stroke: {
        width: 0.1,
        colors: ['#000'],
    },
    colors: ['#0d52ff', '#99cff3'],
    grid: { show: false },
    xaxis: {
        categories: props.products,
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: { show: false },
    },
    yaxis: {
        axisBorder: { show: false },
        axisTicks: { show: false },
    },
    tooltip: {
        intersect: false,
        shared: true,
        x: {
            formatter: function (val) {
                return t('lbl-client-product-state-' + val.toLowerCase() + '-title');
            },
        },
        y: {
            formatter: function (val) {
                return val + ' ' + t('h-clients').toLowerCase();
            },
            title: {
                formatter: function (val) {
                    return t('lbl-client-product-state-' + val);
                },
            },
        },
    },
    legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'right',
        offsetX: 40,
    },
    dataLabels: { enabledOnSeries: [0, 1] },
};

function chartClick (event, chartContext, config) {
    if (config.dataPointIndex < 0 || config.seriesIndex < 0) {
        return;
    }
    const product = props.products[config.dataPointIndex];
    const status = props.series[config.seriesIndex].name;
    emits('clicked', product, status);
}
</script>

<template>
    <content-box>
        <div v-if='showHeader' class='mb-3 flex'>
            <CustomTitle :level='2' no-default-margins class='mb-3' :style-of='3'>
                📈 {{ $t('ttl-client-activation-rate') }}
            </CustomTitle>
            <Tooltip class='mt-2' left>
                <template #trigger>
                    <i class='text-grey-300 fa fa-info-circle ml-1 m-center'></i>
                </template>
                <template #content>
                    <p>
                        {{ $t('p-product-states-tooltip') }}
                    </p>
                </template>
            </Tooltip>
        </div>
        <div class='text-center p-10' v-if='loading'>
            <i class='fa fa-circle-o-notch fa-spin fa-5x'></i>
        </div>
        <div class='text-center p-10' v-else-if='series.length === 0'>
            <p class='text-lg text-grey-300'>
                ⚠️ {{ $t('lbl-data-not-available') }}
            </p>
        </div>
        <div v-else>
            <VueApexCharts
                type='bar'
                height='150'
                :options='chartOptions'
                :series='series'
                @data-point-selection='chartClick'
            />
        </div>
    </content-box>
</template>

<style lang="scss">
.apexcharts-bar-area {
    cursor: pointer;
}
</style>
