<script setup>
import FriendlyButton from '@/clientcomponents/FriendlyButton';
import {Auth} from '@aws-amplify/auth';
import FormInput from '@/components/FormInput';
import {Form} from 'vee-validate';
import {ref} from 'vue';
import notify from '@/notify';
import {t} from '@/i18n';

let currentUser;
const currentFirstName = ref('');
const currentLastName = ref('');

async function loadUser () {
    try {
        currentUser = await Auth.currentAuthenticatedUser();
        currentFirstName.value = currentUser.attributes.given_name || '';
        currentLastName.value = currentUser.attributes.family_name || '';
    } catch (e) {
        notify.error(t('err-unknown'));
    }
}

loadUser();

async function changeInfo (values) {
    try {
        const result = await Auth.updateUserAttributes(currentUser, {
            given_name: values.firstName,
            family_name: values.lastName,
        });
        if (result === 'SUCCESS') {
            notify.success(t('succ-user-settings-change-name'));
        } else {
            notify.error(t('err-unknown-retry-later'));
        }
    } catch (e) {
        if (e && e.message === 'Rate exceeded') {
            notify.error(t('err-rate-exceeded'));
        } else {
            notify.error(t('err-unknown-retry-later'));
        }
    }
}

</script>

<template>
    <div>
        <Form tag='div' ref='changeInfoForm' v-slot='{ values, handleSubmit }'>
            <FormInput
                :label='$t("lbl-firstname")'
                name='firstName'
                rules='required|name'
                ref='firstNameInput'
                :value='currentFirstName'
                edit
                class='mb-3'
            />

            <FormInput
                :label='$t("lbl-lastname")'
                name='lastName'
                rules='required|name'
                ref='lastNameInput'
                :value='currentLastName'
                edit
            />
            <div class='mt-6 flex justify-end'>
                <FriendlyButton
                    label='btn-user-settings-change-name'
                    no-margin
                    fullwidth
                    square
                    extra-small
                    type='submit'
                    @click.prevent='handleSubmit($event, () => { changeInfo(values)})'
                />
            </div>
        </Form>
    </div>
</template>
