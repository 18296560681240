<script setup>
import { ref, watch } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
    name: String,
    value: String,
    label: String,
    rules: String,
    codeLength: {
        type: Number,
        default: 6,
    },
});

const { handleChange, errorMessage } = useField(
    props.name,
    `length:${props.codeLength}|${props.rules || ''}`,
    {
        validateOnValueUpdate: false,
        initialValue: props.value,
    },
);

const code = ref(Array(props.codeLength).fill(''));
const inputs = ref([]);

watch(() => props.value, (newValue) => {
    if (newValue) {
        code.value = newValue.split('').slice(0, props.codeLength);
    }
});

const emitFullCode = () => {
    const fullCode = code.value.join('');
    handleChange(fullCode, false);
};

const handleInput = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
        code.value[index] = value;
        emitFullCode();

        if (index < props.codeLength - 1) {
            inputs.value[index + 1].focus();
        }
    } else {
        inputs.value[index].value = '';
    }
};

const handleKeydown = (e, index) => {
    if (e.key === 'Backspace' && code.value[index] === '') {
        if (index > 0) {
            inputs.value[index - 1].focus();
        }
    }
};

const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').trim();
    if (/^\d+$/.test(pasteData)) {
        const pasteArray = pasteData.split('').slice(0, props.codeLength);
        pasteArray.forEach((digit, i) => {
            code.value[i] = digit;
        });
        emitFullCode();
        inputs.value[props.codeLength - 1].focus();
    }
};
</script>

<template>
    <div class='code-input-wrapper'>
        <label v-if='label' class='form-label'>{{ label }}</label>

        <div class='code-input' :class='{ "has-error": !!errorMessage }'>
            <template v-for='(digit, index) in codeLength' :key='index'>
                <input
                    type='text'
                    class='verification-input'
                    maxlength='1'
                    v-model='code[index]'
                    @input='handleInput($event, index)'
                    @keydown='handleKeydown($event, index)'
                    @paste='handlePaste'
                    ref='inputs'
                    placeholder='0'
                >
            </template>
        </div>

        <p class='code-input-errors' v-show='errorMessage'>
            {{ errorMessage }}
        </p>
    </div>
</template>

<style scoped lang="postcss">
.code-input-wrapper {
  @apply flex flex-col w-full;
}

.form-label {
  @apply font-semibold mb-2 text-sm;
}

.code-input {
  @apply flex gap-2 w-full items-center text-6xl;
}

.verification-input {
  @apply w-full border-solid text-4xl text-grey-600 border-grey-250 border-2 rounded h-14 text-center transition-colors duration-200 ease-in-out;
}

.verification-input:focus {
  @apply border-blue-300 outline-none;
}

.code-input-errors {
  @apply text-red-300 text-sm font-bold mt-2;
}

.has-error .verification-input {
  @apply border-red-300;
}
</style>
