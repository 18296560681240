<script setup>
import List from '@/components/List.vue';
import CodaSignatureListRow from '@/components/CodaSignatureListRow.vue';

defineProps({
    newCodaMandateIds: {
        type: Object,
        required: true,
    },
    codaMandatesAwaitingSignature: {
        type: Object,
        required: true,
    },
    clientV2: {
        type: Object,
        required: true,
    },
});

const emits = defineEmits(['getCodaMandatesEvent']);

</script>

<template>
    <span>
        {{ $t('sign-coda.to-be-signed-section.description', {representative_name: clientV2.representativeName}) }}
    </span>
    <List
        id='mandates-to-be-signed'
        no-border
    >
        <template #rows>
            <CodaSignatureListRow
                v-for='(mandate, i) in codaMandatesAwaitingSignature'
                :key='i'
                :background='Number.isInteger(((i) / 2))'
                :client-v2='clientV2'
                :is-new='newCodaMandateIds.includes(mandate.id)'
                :mandate='mandate'
                @get-coda-mandates-event='emits("getCodaMandatesEvent")'
            />
        </template>
    </List>
</template>
