<script>
    export default {
        name: 'ClientLink',
        props: {
            client: {
                type: Object,
                required: true,
            },
            product: {
                type: String,
                required: false,
            },
            document: String,
        },
        computed: {
            productPath () {
                if (this.product && ['coda', 'soda', 'caro', 'voila'].includes(this.product.toLowerCase())) {
                    return this.product.toLowerCase() + '/';
                }
                return '';
            },
            url () {
                var url = `/organization/${this.$route.params.organizationId}/environment/${this.$route.params.environmentId}/client/${this.client.id || this.client.uuid}/${this.productPath}`;
                if (this.document) {
                    url += '?doc=' + this.document;
                }
                return url;
            },
        },
    };
</script>
<template>
    <router-link :to='url'>
        {{ client.client_code }}
    </router-link>
</template>
