<script>
    import router from '@/router.js';
    import Loader from '@/loader.js';
    import validate from '@/validate.js';
    import notify from '@/notify.js';
    import _ from 'lodash';

    import ClientAddCoda from '@/components/ClientAddCoda.vue';
    import ClientAddSoda from '@/components/ClientAddSoda.vue';
    import RequiredNotice from '@/components/RequiredNotice.vue';
    import Warning from './components/Warning.vue';
    import Popup from '@/clientcomponents/Popup';
    import FormInput from '@/components/FormInput';
    import FormToggle from '@/components/FormToggle';
    import FormSelect from '@/components/FormSelect';
    import ContentBox from '@/components/ContentBox';
    import CustomTitle from '@/components/Title';
    import Translated from '@/components/Translated';
    import DatePicker from '@/components/DatePicker';
    import { gql } from '@apollo/client/core';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import { Form } from 'vee-validate';
    import utils from '../utils';

    export default {
        name: 'FiduNewClient',
        props: {
            currentEnvironment: {
                type: Object,
            },
            accountant: {
                type: Object,
            },
        },
        components: {
            FormInput,
            RequiredNotice,
            Warning,
            Popup,
            ClientAddCoda,
            ClientAddSoda,
            ContentBox,
            CustomTitle,
            Translated,
            DatePicker,
            FriendlyButton,
            // eslint-disable-next-line vue/no-reserved-component-names
            Form,
            FormSelect,
            FormToggle,
        },
        data () {
            return {
                ID: 0,
                clientData: {},
                platformTransferData: {},
                settings: [],
                transferablePopup: false,
                transferPayload: null,
                useContactEmailForPI: true,
                canEditPiEmail: false,
                saving: false,
                clientTransferData: {},
                specialErrors: {
                    uniqueClient: false,
                    uniqueArchivedClientPlatform: false,
                },
                specialErrorsData: {
                    uniqueArchivedClientPlatform: {},
                },
            };
        },
        computed: {
            isFiduExactOnline () {
                return this.currentEnvironment.isExactOnline;
            },
            ctrCodaDeliveryStartDateMinDate () {
                const today = new Date();
                const twoYearsBefore = new Date();
                twoYearsBefore.setFullYear(today.getFullYear() - 2);
                // Min limit must be strictly greater than 2 years ago (>2yAgo)
                return twoYearsBefore.setDate(twoYearsBefore.getDate() + 1);
            },
            ctrCodaDeliveryStartDateMaxDate () {
                return new Date();
            },
            hasCodaOnlyContract () {
                return this.accountant.hasCodaOnlyContract;
            },
        },
        watch: {
            '$i18n.locale': function  () {
                if (Object.keys(this.$refs.clientForm.errors).length !== 0) {
                    this.$nextTick(() => this.$refs.clientForm.validate());
                }
            },
        },
        methods: {
            resetSpecialErrors () {
                this.specialErrors.uniqueClient = false;
                this.specialErrors.uniqueArchivedClientPlatform = false;
                this.specialErrorsData.uniqueArchivedClientPlatform = {};
            },
            clientTransferPayloadPrep (clientData) {
                _.assignIn(this.clientTransferData, {
                    targetFiduciaryId: this.currentEnvironment.id,
                    enterpriseName: clientData.enterpriseName,
                    enterpriseNumber: clientData.enterpriseNumber,
                    hasBelgianVatNumber: clientData.hasBelgianVatNumber,
                    representativeName: clientData.representativeName,
                    representativeFunction: clientData.representativeFunction,
                    address: clientData.address,
                    address2: clientData.address2,
                    zip: clientData.zip,
                    city: clientData.city,
                    clientCode: clientData.clientCode,
                    language: clientData.language,
                    contactName: clientData.representativeName,
                    contactEmail: clientData.contactEmail,
                    exactEmail: clientData.exactEmail,
                    sendCodaAndSodaByMail: clientData.sendCodaAndSodaByMail,
                    // Hardcoded data, required by API:
                    orderVoila: false,
                    voilaDeliveryEmail: null,
                });
            },

            async newClient (values) {
                this.resetSpecialErrors();

                Loader.start();
                this.saving = true;
                try {
                    const input = {
                        ...values,
                        ...{
                            exactEmail: values.exactEmail || null,
                            fiduciaryId: this.currentEnvironment.id,
                            sendCodaAndSodaByMail: false,
                        }};

                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation NewClient($input: NewClientInput!) {
                            newClient(input: $input) {
                                data {
                                    id
                                    address
                                    address2
                                    zip
                                    city
                                    clientCode
                                    contactEmail
                                    exactEmail,
                                    enterpriseName
                                    enterpriseNumber
                                    fiduciaryId
                                    language
                                    representativeFunction
                                    representativeName
                                    sendCodaAndSodaByMail
                                }
                                errors {
                                    code,
                                    detail,
                                    source {
                                        pointer
                                    }
                                }
                            }
                        }`,
                        variables: {
                            input,
                        },
                    });
                    if (data.newClient.errors) {
                        this.saving = false;
                        if (data.newClient.errors.some(item => {
                            return (
                                item.source.pointer === '/data/enterpriseNumber' &&
                                item.detail === 'Conflict with client of another accountant.'
                            );
                        })) {
                            this.transferablePopup = true;

                            this.clientTransferPayloadPrep(input);

                            this.saving = false;
                            Loader.stop();

                            return;
                        }

                        const enterpriseNumberPointer = '/data/enterpriseNumber';
                        let platformTransferArchivedError = data.newClient.errors.find(el => el.source.pointer === enterpriseNumberPointer && el.code === 'platformTransferArchived');
                        if (platformTransferArchivedError) {
                            /* Get error detail to extract the platform name and replace the detail message by a generic message
                                because system will display the detail message in bottom of the form input
                             */
                            const platformName = platformTransferArchivedError.detail.replace('This client already exists and is archived under the ', '').replace(' platform.', '');
                            platformTransferArchivedError.detail = this.$t('err-client-archived-under-another-environment', { 'environmentName': platformName });

                            this.specialErrors.uniqueArchivedClientPlatform = true;
                            this.specialErrorsData.uniqueArchivedClientPlatform = {
                                'platformName': platformName,
                            };
                        } else if (data.newClient.errors.some(el => el.source.pointer === enterpriseNumberPointer && el.code === 'unique')) {
                            this.specialErrors.uniqueClient = true;
                        }

                        // Set error on inputs
                        validate.reportGQLFieldErrors(
                            data.newClient.errors,
                            this.$refs.clientForm,
                            {
                                'Client code already used for this fiduciary.': 'err-client-code-not-unique',
                                'Already in use by a client of another fiduciary.': 'err-exact-email-not-unique',
                            },
                        );

                        Loader.stop();
                    } else {
                        Loader.stop();
                        notify.success(this.$t('suc-client-created'));
                        await router.push(`/organization/${this.$route.params.organizationId}/environment/${this.$route.params.environmentId}/client/${data.newClient.data.id}`);
                    }
                } catch (error) {
                    notify.error(this.$t('err-unknown'));
                    this.saving = false;
                    Loader.stop();
                }
            },

            async submitClientTransferForm (values) {
                Loader.start();
                this.saving = true;

                const bankAccountInputsValid = await this.$refs.bankAccountInputs.validateBankAccounts();

                let hasDuplicatedSocialOffices = false;

                if (!this.hasCodaOnlyContract) {
                    // validate if there is no duplicated social offices in frontend
                    // because the backend endpoint ("create CTR") is not accurate enough in response to identify which record is duplicated
                    hasDuplicatedSocialOffices = this.$refs.socialOfficesInputs.hasDuplicatedSocialOffices();
                }

                if (!bankAccountInputsValid || hasDuplicatedSocialOffices) {
                    Loader.stop();
                    this.saving = false;
                    return;
                }

                // Reformat form data for backends but keep original format for form fields display
                let createClientTransferInput = Object.assign({}, this.clientTransferData);

                // reformat bank accounts to remove spaces
                createClientTransferInput.bankAccounts = values.newBankAccounts && values.newBankAccounts.length > 0 ? values.newBankAccounts.map(ba => ba.replace(/\s+/g, '')) : [];

                // only push social office not null
                createClientTransferInput.socialOffices = [];
                if (values.socialOffices && values.socialOffices.length > 0) {
                    values.socialOffices.forEach(so => {
                        if (so) {
                            createClientTransferInput.socialOffices.push(so);
                        }
                    });
                }

                // format codaDeliveryStartDate
                createClientTransferInput.codaDeliveryStartDate = values.codaDeliveryStartDate ? utils.date2strYYYYMMDD(values.codaDeliveryStartDate) : null;

                try {
                    const createMutation = await this.$apollo.mutate({
                        mutation: gql`mutation ($input: ClientTransferRequestCreateInput!) {
                            createClientTransferRequest(input: $input) {
                                errors { code, detail, source { pointer } }
                            }
                        }`,
                        variables: {
                            input: createClientTransferInput,
                        },
                    });

                    const createResponse = createMutation.data.createClientTransferRequest;

                    if (createResponse.errors) {
                        const allErrors = createResponse.errors;

                        // ! No errors on fields of the form expected: frontend validation is enough

                        // Global error: error with pointer to "/data"
                        const globalError = allErrors.find(error => {
                            return (
                                error.source && error.source.pointer && error.source.pointer === '/data'
                            );
                        });

                        if (globalError) {
                            if (globalError.code === 'alreadyExists' || globalError.code === 'alreadyExistsForFiduciary') {
                                // there is "alreadyExists" errors; display specific message
                                notify.error(this.$t('err-client-transfer-already-exists'));
                            } else {
                                // unexpected global error
                                notify.error(this.$t('err-unknown'));
                            }
                        } else {
                            // unexpected error
                            notify.error(this.$t('err-unknown'));
                        }

                        Loader.stop();
                        this.saving = false;
                    } else {
                        Loader.stop();
                        this.saving = false;
                        notify.success(this.$t('suc-client-transferred'));
                        await router.push(`/organization/${this.$route.params.organizationId}/environment/${this.$route.params.environmentId}/clients/transfers/`);
                    }
                } catch (error) {
                    Loader.stop();
                    this.saving = false;
                    notify.error(this.$t('err-unknown'));
                }
            },
            formatString: validate.formatString,
        },
    };
</script>
<template>
    <section>
        <div v-if='saving' class='cb-form-overlay'></div>

        <Popup :show='transferablePopup' :close='() => { transferablePopup = false }' :scroll='false'>
            <template #header>
                {{ $t('ttl-client-transfer-request-popup') }}
            </template>
            <Form
                ref='clientTransferForm'
                tag='div'
                id='clientTransferForm'
                @submit='submitClientTransferForm'
            >
                <p>
                    {{ $t('p-client-transfer-request-popup') }}
                </p>
                <div class='cb-fidu-title'>
                    <h2 class='mt-8 mb-3'>
                        {{ $t('ttl-add-coda') }}
                    </h2>
                </div>

                <ClientAddCoda
                    ref='bankAccountInputs'
                    :form-ref='$refs.clientTransferForm'
                />
                <DatePicker
                    name='codaDeliveryStartDate'
                    :label='$t("lbl-resend-coda-transfer-title")'
                    :placeholder='$t("lbl-resend-coda-transfer-date-placeholder")'
                    :min-date='ctrCodaDeliveryStartDateMinDate'
                    :max-date='ctrCodaDeliveryStartDateMaxDate'
                    edit
                    class='mt-6'
                >
                    <template #info>
                        {{ $t('lbl-resend-coda-transfer-info') }}
                    </template>
                </DatePicker>

                <template v-if='!hasCodaOnlyContract'>
                    <div class='cb-fidu-title'>
                        <div class='cb-fidu-actions'></div>
                        <h2 class='mt-8 mb-6'>
                            {{ $t('ttl-add-soda') }}
                        </h2>
                    </div>

                    <ClientAddSoda
                        ref='socialOfficesInputs'
                        :form-ref='$refs.clientTransferForm'
                    />
                </template>

                <div class='flex'>
                    <FriendlyButton
                        label='btn-client-transfer-request-close'
                        :action='() => { transferablePopup = false }'
                        square
                        extra-small
                        no-margin
                        secondary
                        class='mr-2 ml-auto'
                    />
                    <FriendlyButton
                        label='btn-client-transfer-request-confirms'
                        type='submit'
                        square
                        extra-small
                        no-margin
                        id='confirmOrderSodaMandateButton'
                    />
                </div>
            </Form>
        </Popup>
        <div class='cb-fidu-title'>
            <CustomTitle class='mt-12 mb-6'>
                {{ $t('nav-fidu-client-new') }}
            </CustomTitle>
        </div>
        <p>
            {{ $t('p-info-client-new-1') }}
            <br>
            {{ $t('p-info-client-new-2') }}
        </p>
        <Form
            ref='clientForm'
            id='newClientForm'
            @submit='newClient'
        >
            <content-box :title='$t("h-client-info")'>
                <template #actions>
                    <div class='text-center'>
                        <FriendlyButton
                            label='btn-reset'
                            symbol='undo'
                            square
                            secondary
                            extra-small
                            no-margin
                            class='mr-2'
                            type='reset'
                        />
                        <FriendlyButton
                            label='btn-save-client'
                            symbol='save'
                            square
                            extra-small
                            no-margin
                            type='submit'
                        />
                    </div>
                </template>

                <div class='grid grid-cols-3 gap-6'>
                    <div>
                        <div class='grid grid-cols-1 gap-3'>
                            <FormInput
                                name='clientCode'
                                :label='$t("lbl-client-code")'
                                :placeholder='$t("lbl-client-code")'
                                edit
                                rules='required|max:50'
                                id='clientCode'
                            />
                            <FormInput
                                name='contactEmail'
                                :label='$t("lbl-client-email")'
                                :placeholder='$t("lbl-email")'
                                edit
                                rules='required|email|max:254'
                                id='contactEmail'
                            />

                            <FormSelect
                                name='language'
                                :label='$t("lbl-language")'
                                :placeholder='$t("lbl-language-select")'
                                edit
                                id='language'
                                rules='required'
                                :options='[
                                    {
                                        label: $t("lbl-dutch"),
                                        value: "nl",
                                    },
                                    {
                                        label: $t("lbl-french"),
                                        value: "fr",
                                    },
                                    {
                                        label: $t("lbl-english"),
                                        value: "en",
                                    }
                                ]'
                            />

                            <FormInput
                                v-if='isFiduExactOnline'
                                name='exactEmail'
                                :label='$t("lbl-exact-online")'
                                :placeholder='$t("lbl-email")'
                                :edit='true'
                                nullable
                                rules='exactEmail|max:254'
                                id='exactEmail'
                            />
                        </div>
                    </div>
                    <div>
                        <div class='grid grid-cols-1 gap-3'>
                            <FormInput
                                name='enterpriseName'
                                :label='$t("lbl-enterprise-name")'
                                :placeholder='$t("lbl-enterprise-name")'
                                rules='required|max:160'
                                edit
                                id='enterpriseName'
                            />
                            <FormInput
                                name='enterpriseNumber'
                                :label='$t("lbl-enterprise-number")'
                                placeholder='0123456789'
                                rules='enterpriseNumberFormat|required'
                                edit
                                id='enterpriseNumber'
                                :formatter='str => formatString(str, "DDDDDDDDDD")'
                                format-on-type
                            />
                            <FormToggle
                                :value='true'
                                name='hasBelgianVatNumber'
                                :label='$t("lbl-has-belgian-vat-number")'
                                edit
                                id='hasBelgianVatNumber'
                                class='my-2'
                            />
                            <FormInput
                                name='representativeName'
                                :label='$t("lbl-representative-name")'
                                :placeholder='$t("lbl-representative-name")'
                                rules='composedName|required|max:100'
                                edit
                                id='representativeName'
                                clean
                                :info='$t("p-tooltip-representative-name")'
                            />
                            <FormInput
                                name='representativeFunction'
                                :label='$t("lbl-representative-function")'
                                :placeholder='$t("lbl-representative-function")'
                                rules='required|max:128'
                                edit
                                id='representativeFunction'
                            />
                        </div>
                    </div>
                    <div>
                        <div class='grid grid-cols-1 gap-3'>
                            <FormInput
                                name='address'
                                :label='$t("lbl-address")'
                                :placeholder='$t("lbl-address-line-1")'
                                rules='required|max:100'
                                edit
                                id='address'
                            />
                            <FormInput
                                name='address2'
                                :placeholder='$t("lbl-address-line-2")'
                                edit
                                rules='max:100'
                                id='address2'
                            />
                            <FormInput
                                name='zip'
                                :label='$t("lbl-zip")'
                                :placeholder='$t("lbl-zip")'
                                rules='required|min:4|max:20'
                                edit
                                id='zip'
                            />
                            <FormInput
                                name='city'
                                :label='$t("lbl-city")'
                                :placeholder='$t("lbl-city")'
                                rules='required|max:50'
                                edit
                                id='city'
                            />
                        </div>
                    </div>
                </div>

                <div class='grid box error mb-2 mt-2' v-if='specialErrors.uniqueClient'>
                    <div class='grid-cols-12'>
                        <p class='m-0 ml-3'>
                            <Translated>
                                <template #en>
                                    This company is already registered under this environment or under another environment.
                                    If the company is registered under another environment, you can request an environment transfer.
                                    For more information, visit our <a v-if='!accountant.isDirectCustomer' href='https://faq.codabox.com/en/support/solutions/articles/75000027132' target='_blank'>CodaBox FAQ</a> <a v-else href='https://faq.codabox.com/en/support/solutions/articles/75000123386' target='_blank'>CodaBox FAQ</a>
                                </template>
                                <template #nl>
                                    Dit dossier is al geregistreerd onder deze omgeving of onder een andere omgeving.
                                    Als het dossier geregistreerd staat onder een andere omgeving, kun je een overdracht van omgeving aanvragen.
                                    Voor meer informatie, bezoek onze <a v-if='!accountant.isDirectCustomer' href='https://faq.codabox.com/nl/support/solutions/articles/75000027132' target='_blank'>CodaBox FAQ</a> <a v-else href='https://faq.codabox.com/nl/support/solutions/articles/75000123386' target='_blank'>CodaBox FAQ</a>
                                </template>
                                <template #fr>
                                    Ce dossier est déjà inscrit sous cet environnement ou sous un autre environnement.
                                    Si le dossier est enregistré sous un autre environnement, vous pouvez demander un transfert d'environnement.
                                    Pour plus d’informations, visitez notre <a v-if='!accountant.isDirectCustomer' href='https://faq.codabox.com/fr/support/solutions/articles/75000027132' target='_blank'>FAQ CodaBox</a> <a v-else href='https://faq.codabox.com/fr/support/solutions/articles/75000123386' target='_blank'>FAQ CodaBox</a>
                                </template>
                            </Translated>
                        </p>
                    </div>
                </div>

                <div class='grid box error mb-2' v-if='specialErrors.uniqueArchivedClientPlatform'>
                    <div class='grid-cols-12'>
                        <p class='m-0'>
                            <Translated>
                                <template #en>
                                    This company already exists and is archived under the "{{ specialErrorsData.uniqueArchivedClientPlatform.platformName }}" environment.<br>
                                    To proceed you must add this company again under that environment as a "New Company" and request an environment transfer afterwards.<br>
                                    For more information, visit our <a href='https://faq.codabox.com/en/support/solutions/articles/75000086599-archiving-clients-via-mycodabox-questions-answers/#transfert' target='_blank'>CodaBox FAQ</a>.
                                </template>
                                <template #nl>
                                    Dit dossier bestaat al en is gearchiveerd onder de "{{ specialErrorsData.uniqueArchivedClientPlatform.platformName }}" omgeving.<br>
                                    Om verder te gaan moet je dit dossier opnieuw toevoegen onder die omgeving als een "Nieuw dossier" en daarna een transfer van omgeving aanvragen.<br>
                                    Voor meer informatie, bezoek onze <a href='https://faq.codabox.com/nl/support/solutions/articles/75000086599-klanten-archiveren-via-mycodabox-vragen-antwoorden/#transfert' target='_blank'>CodaBox FAQ</a>.
                                </template>
                                <template #fr>
                                    Ce dossier existe déjà et est archivé sous l'environnement "{{ specialErrorsData.uniqueArchivedClientPlatform.platformName }}".<br>
                                    Pour poursuivre, vous devez ajouter à nouveau ce dossier sous cet environnement en tant que "Nouveau dossier" et ensuite demander un transfert d'environnement.<br>
                                    Pour plus d'informations, veuillez consulter notre <a href='https://faq.codabox.com/fr/support/solutions/articles/75000086599-archivage-de-clients-via-mycodabox-questions-r%C3%A9ponses/#transfert' target='_blank'>FAQ CodaBox</a>.
                                </template>
                            </Translated>
                        </p>
                    </div>
                </div>

                <Warning v-if='clientData.exactEmail && !isFiduExactOnline'>
                    <template #header>
                        {{ $t('h-exact-email-warning') }}
                    </template>
                    {{ $t('p-exact-email-warning') }}
                </Warning>

                <RequiredNotice />
            </content-box>
        </Form>
    </section>
</template>
