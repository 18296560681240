<script>
import {VoilaMandateReasonCodes, VoilaMandateStates} from '@/constants/voila2';
import StatePill from '@/components/StatePill.vue';

export default {
    name: 'VoilaStatePill',
    props: {
        status: String,
        reasonCode: String,
        initialAlreadyRegisteredOnPeppolErrorDate: String,
        accessPointNames: String,
        inline: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        StatePill,
    },
    computed: {
        voilaMandateStatusLabel () {
            if (!this.status) {
                return this.$t('voila-status-not-ordered');
            } else {
                return this.$t({
                    [VoilaMandateStates.Invited]: 'voila-status-invited',
                    [VoilaMandateStates.InvitationExpired]: 'voila-status-invitationExpired',
                    [VoilaMandateStates.InValidation]: 'voila-status-inValidation',
                    [VoilaMandateStates.InRegistration]: 'voila-status-inRegistration',
                    [VoilaMandateStates.Problem]: 'voila-status-problem',
                    [VoilaMandateStates.Active]: 'voila-status-active',
                    [VoilaMandateStates.Cancelled]: 'voila-status-cancelled',
                }[this.status]);
            }
        },
        voilaMandateStatusLegend () {
            if (!this.status) {
                return this.$t('voila-status-legend-not-ordered');
            } else {
                return {
                    [VoilaMandateStates.Invited]: this.$t('voila-status-legend-invited'),
                    [VoilaMandateStates.InvitationExpired]: this.$t('voila-status-legend-invitationExpired'),
                    [VoilaMandateStates.InValidation]: this.$t('voila-status-legend-inValidation'),
                    [VoilaMandateStates.InRegistration]: this.$t('voila-status-legend-inRegistration'),
                    [VoilaMandateStates.Active]: this.$t('voila-status-legend-active'),
                    [VoilaMandateStates.Problem]: this.voilaMandateReasonCodeLegend('voila-status-legend-problem'),
                    [VoilaMandateStates.Cancelled]: this.voilaMandateReasonCodeLegend('voila-status-legend-cancelled'),
                }[this.status];
            }
        },

        voilaMandateStatusBackgroundClass () {
            if (!this.status || this.status === VoilaMandateStates.Cancelled) {
                return 'bg-grey-300';
            } else if ([
                VoilaMandateStates.InvitationExpired,
                VoilaMandateStates.Problem,
            ].includes(this.status)) {
                return 'bg-red-300';
            } else {
                return 'bg-green-300';
            }
        },
    },
    methods: {
        voilaMandateReasonCodeLegend (defaultMessage) {
            if (!this.reasonCode) {
                return this.$t(defaultMessage);
            } else {
                return {
                    [VoilaMandateReasonCodes.AlreadyRegisteredOnPeppol]: this.$t('voila-reason-legend-already-registered-on-peppol', { accessPoints: this.accessPointNames, initialAlreadyRegisteredOnPeppolErrorDate: this.initialAlreadyRegisteredOnPeppolErrorDate }),
                    [VoilaMandateReasonCodes.KboVerificationRefused]: this.$t('voila-status-legend-problem'), // TODO add a specific error message for kbo verification issue?
                    [VoilaMandateReasonCodes.EmailVerificationExpired]: this.$t('voila-status-legend-invitationExpired'),
                    [VoilaMandateReasonCodes.UserRequested]: this.$t('voila-status-legend-cancelled'),
                    [VoilaMandateReasonCodes.GeneralError]: this.$t('voila-status-legend-problem'),
                }[this.reasonCode];
            }
        },
    },
};
</script>

<template>
    <div class='voila-state-pill'>
        <StatePill :label='voilaMandateStatusLabel' :legend='voilaMandateStatusLegend' :background-class='voilaMandateStatusBackgroundClass' :inline='inline' />
    </div>
</template>

<style lang="scss" scoped>
  .voila-state-pill {
    white-space: pre-wrap;
}
</style>
