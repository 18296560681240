<script setup>
import { useField } from 'vee-validate';
import Tooltip from '@/components/Tooltip';
import { watch } from 'vue';

const props = defineProps({
    value: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        required: true,
    },
    label: String,
    placeholder: {
        type: String,
        default: '',
    },
    narrow: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: String,
    },
    info: String,
    edit: {
        type: Boolean,
        default: false,
    },
    disabled: Boolean,
    disabledInfo: {
        type: String,
        default: () => '',
    },
    checkedValue: {
        type: [String, Boolean],
        default: true,
    },
    uncheckedValue: {
        type: [String, Boolean],
        default: false,
    },
});

const emits = defineEmits(['update:modelValue', 'change']);

const { checked: inputChecked, errorMessage, handleChange } = useField(
    props.name,
    null,
    {
        type: 'checkbox',
        label: props.label,
        initialValue: props.value,
        checkedValue: props.checkedValue,
        uncheckedValue: props.uncheckedValue,
    });

function calculateValueToEmit (c) {
    return c ? props.checkedValue : props.uncheckedValue;
}

function onCheckboxChange (e) {
    handleChange(e.target.value);
    emits('update:modelValue', calculateValueToEmit(e.target.value));
    emits('change', calculateValueToEmit(e.target.value));
}

// react on v-model change
watch(() => props.modelValue, (newValue) => {
    handleChange(newValue);
});
</script>

<template>
    <div class='form-checkbox-wrapper' :class='{ narrow }'>
        <div class='form-checkbox' :class='{ "has-error": !!errorMessage }'>
            <Tooltip v-if='disabled && disabledInfo.length > 0' left>
                <template #trigger>
                    <input
                        type='checkbox'
                        :checked='inputChecked'
                        :disabled='true'
                        :id='name'
                    >
                    <label :for='name' v-if='label'>
                        {{ label }}
                    </label>
                </template>
                <template #content>
                    {{ disabledInfo }}
                </template>
            </Tooltip>

            <template v-else>
                <input
                    type='checkbox'
                    :checked='inputChecked'
                    @change='onCheckboxChange'
                    :disabled='disabled || !edit'
                    :id='name'
                >
                <label :for='name' v-if='label'>
                    {{ label }}
                </label>
                <span v-if='info' class='ml-2 font-normal'>
                    <Tooltip>
                        <template #trigger>
                            <i class='fa fa-info-circle'></i>
                        </template>
                        <template #content>
                            <div>{{ info }}</div>
                        </template>
                    </Tooltip>
                </span>
            </template>
        </div>
        <p class='form-checkbox-errors' v-show='errorMessage'>
            {{ errorMessage }}
        </p>
    </div>
</template>

<style scoped>
.form-checkbox-wrapper {
    @apply inline-block w-full;
}

.form-checkbox {
    @apply flex items-center;
}

label {
    @apply text-grey-700 font-bold m-0 ml-3;
}

.form-checkbox input[type="checkbox"] {
    @apply cursor-pointer mt-0;
}

.form-checkbox input[type="checkbox"]:disabled {
    @apply bg-blue-200 cursor-not-allowed;
}

.form-checkbox-errors {
    @apply text-red-300 inline-block font-bold text-sm mt-1 mb-0;
}

.form-checkbox-wrapper.narrow {
    @apply w-80;
}

.has-error label {
    @apply text-red-300;
}
</style>
