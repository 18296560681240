<template>
    <div class='ml-5'>
        <h1 v-if='!reactivate' class='flex mb-5 text-4xl mt-4'>
            {{ $t('ttl-sync-activation-token-popup-activate') }}
        </h1>
        <h1 v-if='reactivate' class='flex mb-4 text-4xl mt-4'>
            {{ $t('ttl-sync-activation-token-popup-reactivate') }}
        </h1>
        <div v-if='reactivate' class='bg-amber-50 border border-amber-400 text-amber-700 px-4 py-3 rounded mb-5'>
            {{ $t('txt-sync-activation-token-popup1') }}
            <!-- ⚠️ Reactivating a connection stops the current connection. You'll be able to download files with the newly activated connection only. -->
        </div>
        <div>
            <Translated>
                <template #en>
                    Follow the steps below to set up SyncTool connection <strong>{{ podName }} </strong>.
                </template>
                <template #fr>
                    Suivez les étapes ci-dessous pour activer la connexion SyncTool <strong>{{ podName }} </strong>.
                </template>
                <template #nl>
                    Volg onderstaande stappen om SyncTool-verbinding <strong>{{ podName }} </strong> te activeren.
                </template>
            </Translated>
        </div>
        <h3 class='flex mb-2 text-2xl mt-2 '>
            {{ $t('txt-explanation-step-2-header-1') }}
        </h3>
        <p class='mb-3'>
            {{ $t('txt-explanation-step-2-paragraph-1') }} <br>
        </p>
        <div class='mt-3 mb-6 text-center'>
            <a :href='downloadLink' target='_blank' class='mr-2'>
                <FriendlyButton
                    square
                    extra-small
                    no-margin
                    label='btn-download'
                    symbol='windows'
                    fullwidth
                    secondary
                />
            </a>
            <!-- <a :href='downloadLink' target='_blank' class='ml-2'>
                <FriendlyButton
                    square
                    extra-small
                    no-margin
                    label='btn-download'
                    fullwidth
                    symbol='apple'
                    secondary
                />
            </a> -->
        </div>
        <h3 class='flex mb-2 text-2xl mt-2'>
            {{ $t('txt-explanation-step-2-header-2') }}
        </h3>
        <p class='mb-3'>
            {{ $t('txt-explanation-step-2-paragraph-2') }} <br>
        </p>
        <p class='mt-3 mb-6 text-center'>
            <FriendlyButton
                :action='() => copyToClipboard(token)'
                symbol='clipboard'
                extra-small
                no-margin
                square
                secondary
                fullwidth
                :disabled='loading'
            >
                <span v-if='loading' class='font-mono uuid align-middle w-auto'>generating code...</span>
                <span v-if='!loading' class='font-mono uuid align-middle w-auto'>{{ token }}</span>
            </FriendlyButton>
        </p>
        <p class='mt-3'>
            {{ $t('txt-explanation-step-2-paragraph-3') }}
        </p>
        <p class='mt-3'>
            {{ $t('txt-explanation-step-2-paragraph-4') }}
        </p>
        <a :href='faqLink' target='_blank'>
            {{ $t('txt-explanation-step-2-paragraph-4-link') }}
        </a>
        <div class='text-center mt-5' style='padding-bottom: 0%;'>
            <FriendlyButton
                label='btn-close'
                :action='onDone_close'
                symbol='times'
                extra-small
                square
            />
        </div>
    </div>
</template>

<script>
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { gql } from '@apollo/client/core';
import notify from '@/notify.js';
import { simpleLocale } from '@/i18n';
import Translated from '@/components/Translated';

export default {
    props: {
        onDone: Function,
        onError: Function,
        podUuid: String,
        podName: String,
        reactivate: Boolean,
    },
    components: {
        FriendlyButton,
        Translated,
    },
    data () {
        return {
            loading: false,
            token: null,
        };
    },
    computed: {
        language () {
            return simpleLocale(this.$i18n.locale);
        },
        downloadLink () {
            return {
                en: 'https://faq.codabox.com/en/support/solutions/articles/75000085068-codabox-synctool-user-manual#download',
                fr: 'https://faq.codabox.com/fr/support/solutions/articles/75000085068-codabox-synctool-user-manual#download',
                nl: 'https://faq.codabox.com/nl/support/solutions/articles/75000085068-codabox-synctool-user-manual#download',
            }[this.language];
        },
        faqLink () {
            return {
                en: 'https://faq.codabox.com/en/support/solutions/articles/75000085068-codabox-synctool-user-manual',
                fr: 'https://faq.codabox.com/fr/support/solutions/articles/75000085068-codabox-synctool-user-manual',
                nl: 'https://faq.codabox.com/nl/support/solutions/articles/75000085068-codabox-synctool-user-manual',
            }[this.language];
        },
    },
    created () {
        this.getToken();
    },
    methods: {
        async getToken () {
            this.loading = true;
            this.token = await this.generateToken();
            this.loading = false;
        },
        async generateToken () {
            try {
                const results = await this.$apollo.query({
                    query: gql`query createSyncActivation($podUuid: String) {
                        createSyncActivation(podUuid: $podUuid) {
                            token
                        }
                    }`,
                    variables: {
                        podUuid: this.podUuid,
                    },
                });
                this.token = results.data.createSyncActivation.token;
                return this.token;
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            } finally {
                this.loading = false;
            }
        },
        async copyToClipboard (token) {
            try {
                await navigator.clipboard.writeText(token);
                notify.success(this.$t('p-sync-activation-token-copied-clipboard-success'));
            } catch (err) {
                notify.error(this.$t('p-sync-activation-token-copied-clipboard-error'));
            }
        },
        onDone_close () {
            this.onDone();
        },
    },
};
</script>

<style lang="scss" scoped>
.info-row {
    @apply mb-2;
}

.info-row:last-child {
    @apply mb-0;
}

.info-row span {
    @apply font-bold mr-2;
}
.uuid {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
