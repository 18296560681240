<script setup>

import notify from '@/notify';
import Popup from '@/clientcomponents/Popup';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { Form } from 'vee-validate';
import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    client: {
        type: Object,
    },
    iban: {
        type: String,
    },
    show: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close', 'detached']);

function close () {
    emit('close');
}

function detached () {
    emit('detached');
}

async function detach (values) {
    const { mutate: detachBankAccount } = await useMutation(gql`
        mutation DetachBankAccount($input: DetachBankAccountInput!) {
            detachBankAccount(input: $input) {
                errors {
                    code
                    detail
                    source {
                        pointer
                    }
                }
            }
        }
    `);

    try {
        const res = (await detachBankAccount({input: {iban: props.iban, clientId: props.client.id}})).data.detachBankAccount;

        if (res.errors) {
            notify.error(t('err-could-not-detach', {error: res.errors[0]?.code}));
            return;
        }
        notify.success(t('detach-success', {iban: props.iban, name: props.client.enterpriseName}));
        detached();
        close();
    } catch (error) {
        notify.error(t('err-unknown-retry-later'));
    }
}

</script>

<template>
    <Form @submit='detach'>
        <Popup
            :show='props.show'
            :close='close'
            small
            small-paddings
        >
            <template #header>
                {{ $t('Are-you-sure') }}
            </template>

            <p>
                {{ $t('detach-popup-description', {iban: props.iban, name: props.client.enterpriseName, clientCode: props.client.clientCode}) }}
            </p>
            <template #buttons>
                <div class='flex'>
                    <FriendlyButton
                        label='btn-cancel'
                        class='mr-3 ml-auto'
                        :action='close'
                        symbol='times'
                        small
                        no-margin
                        secondary
                        id='detach-bank-account-cancel'
                    />
                    <FriendlyButton
                        label='btn-detach-bank-account'
                        small
                        no-margin
                        symbol='check'
                        id='detach-bank-account-do'
                        type='submit'
                    />
                </div>
            </template>
        </Popup>
    </Form>
</template>
