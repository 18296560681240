<script>
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';

    export default {
        name: 'ArchivedComponent',
        components: {
            FriendlyButton,
        },
        computed: {
            mandateType () { return this.$route.params.type; },
            mandateOrService () {
                return {
                    'coda': 'mandate',
                    'soda': 'mandate',
                }[this.mandateType] || 'service';
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <h1 class='page-header' v-if='mandateOrService === "mandate"'>
                {{ $t('h-archived') }}
            </h1>
            <h1 class='page-header' v-else>
                {{ $t('h-archived-service') }}
            </h1>
        </div>

        <div class='grid box success'>
            <div class='grid-cols-8'>
                <p v-if='mandateType === "coda"'>
                    {{ $t('p-archived-coda-success') }}
                </p>
                <p v-if='mandateType === "soda"'>
                    {{ $t('p-archived-soda-success') }}
                </p>
                <p v-if='mandateType === "purchase-invoice-delivery"'>
                    {{ $t('p-archived-purchase-success') }}
                </p>
            </div>
        </div>

        <div class='mb-6'></div>

        <div class='grid'>
            <div class='grid-cols-12'>
                <form class='form-inline text-center'>
                    <FriendlyButton
                        label='btn-continue'
                        :action='$router.back()'
                        symbol='chevron-right'
                        square
                        extra-small
                        no-margin
                        secondary
                        class='mr-2'
                    />
                </form>
            </div>
        </div>

        <div class='mb-24'></div>
    </div>
</template>
