<script>
import clickOutside from '@/directives/click-outside';

export default {
    name: 'DropdownV2',
    props: {
        icon: {
            type: String,
            default: 'chevron-down',
        },
        name: String,
        value: String || Array || Number,
        defaultValue: String,
        modelValue: String || Array || Number,
        nullable: {
            type: Boolean,
            default: false,
        },
        direction: {
            type: String,
            default: 'left',
        },
        buttonSmall: {
            type: Boolean,
            default: false,
        },
        buttonExtraSmall: {
            type: Boolean,
            default: false,
        },
        semiTransparent: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        noSelected: {
            type: Boolean,
            default: false,
        },
        inverted: {
            type: Boolean,
            default: false,
        },
        whiteName: {
            type: Boolean,
            default: false,
        },
        accordion: {
            type: Boolean,
            default: false,
        },
        noMargins: {
            type: Boolean,
            default: false,
        },
        buttonExtraClasses: {
            type: String,
            default: () => '',
        },
        menuExtraClasses: {
            type: String,
            default: () => '',
        },
        secondary: {
            type: Boolean,
            default: false,
        },
        split: {
            type: Boolean,
            default: false,
        },
        bigIcon: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        darkMenu: {
            type: Boolean,
            default: false,
        },
        buttonBig: {
            type: Boolean,
            default: false,
        },
        stopPropagation: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    directives: {
        clickOutside: clickOutside,
    },
    data () {
        return {
            menuOpened: false,
            selectedItem: null,
        };
    },
    // VUE-3-MIGRATION-DIRT
    compatConfig: { COMPONENT_V_MODEL: false },
    computed: {
        options () {
            // since de vue 3 updgrade, we have to get childs components one level deeper when they originate from a v-for...
            const defaultSlot = this.$slots.default();
            if (defaultSlot.length === 1 && !defaultSlot[0].componentOptions) {
                return defaultSlot[0].children;
            } else {
                return defaultSlot;
            }
        },
        computedValue () {
            return this.modelValue || this.value;
        },
        selected: {
            get: function () {
                return this.computedValue;
            },
            set: function (newValue) {
                this.updateSelectedItem(newValue);
                this.$emit('update:modelValue', newValue);
                this.$emit('input', newValue);
            },
        },
    },
    watch: {
        modelValue: function () {
            this.updateSelectedItem(this.selected);
        },
        value: function () {
            this.updateSelectedItem(this.selected);
        },
        '$i18n.locale': function  () {
            this.updateSelectedItem(this.selected);
        },
    },
    created () {
        if (this.modelValue || this.value) {
            this.updateSelectedItem(this.computedValue);
        }
    },
    // 'activate' is emitted through the child DropdownItem.vue
    emits: ['update:modelValue', 'input', 'changed', 'activate'],
    methods: {
        updateSelectedItem (value) {
            this.options.map(option => {
                if (option.props && option.props.id === value) {
                    this.$nextTick(() => {
                        this.selectedItem = option.props.name;
                    });
                }
            });
        },
        toggle (e) {
            if (this.disabled) {
                return;
            }
            if (this.stopPropagation) e.stopPropagation();
            this.menuOpened = !this.menuOpened;
        },
        close () {
            this.menuOpened = false;
        },
        change (selected) {
            if (this.selected !== selected) {
                this.$emit('update:modelValue', selected);
                this.$emit('changed', selected);
                this.selected = selected;
            }
            this.close();
        },
        reset () {
            this.change(this.defaultValue ? this.defaultValue : null);
        },
    },
};
</script>

<template>
    <div class='dropdown-v2' v-click-outside='close'>
        <div
            class='dropdown-v2-button'
            :class='[{
                "dropdown-v2-button-small": buttonSmall,
                "dropdown-v2-button-extra-small": buttonExtraSmall,
                "dropdown-v2-button-big": buttonBig,
                "semi-transparent": semiTransparent,
                "transparent": transparent,
                "whiteName": whiteName,
                "accordion": accordion,
                "noMargins": noMargins,
                "secondary": secondary,
                "opacity-50 cursor-not-allowed": disabled,
                menuOpened,
                dark,
            }, buttonExtraClasses]'
            @click='toggle'
        >
            <div class='flex flex-grow'>
                <span v-if='name'>{{ name }}</span>
                <span class='flex items-center' v-if='selected'>
                    <span class='separator mr-2' :class='{ "semi-transparent": semiTransparent }' v-if='!split && name && !noSelected'>|</span>
                    <div v-if='!noSelected'>
                        {{ selectedItem }}
                    </div>
                    <div v-if='nullable' class='ml-2 text-grey-300 cursor-pointer hover:text-blue-300' @click='reset'><i class='fa fa-times'></i></div>
                </span>
            </div>
            <span
                class='dropdown-v2-icon'
                :class='[{
                    "dropdown-v2-icon__active": menuOpened,
                    "dropdown-v2-icon--upper": icon === "chevron-down",
                    "ml-1": (name || selectedItem) && buttonExtraSmall,
                    "ml-3": (name || selectedItem) && !buttonExtraSmall,
                    "m-0": !(name || selectedItem),
                    bigIcon
                }]'
                v-if='icon'
            >
                <i class='fa' :class='`fa-${icon}`'></i>
            </span>
        </div>
        <svg
            width='16'
            height='6'
            viewBox='0 0 16 6'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            class='dropdown-v2__triangle'
            :class='[{"dropdown-v2__triangle--active": menuOpened}, direction]'
            v-if='!accordion'
        >
            <path d='M8 0.5C6 0.5 0.5 6 0.5 6H15.5C15.5 6 10 0.5 8 0.5Z' fill='white' />
        </svg>
        <div
            :class='[
                menuExtraClasses,
                {
                    "dropdown-v2-menu__active": menuOpened,
                    "accordion": accordion,
                    "transparent": transparent,
                    "bg-white" : !darkMenu,
                    "bg-grey-250": darkMenu,
                },
                direction,
            ]'
            class='dropdown-v2-menu overflow-y-auto'
        >
            <slot></slot>
        </div>
    </div>
</template>

<style lang="postcss">
.dropdown-v2 {
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
}

.dropdown-v2__triangle {
    @apply absolute -bottom-3 w-6;
    margin-left: calc(50% - 8px);
    opacity: 0;
    pointer-events: none;
    transform: translateY(5px);
    transition: all ease-in-out .1s;
}

.dropdown-v2__triangle--active {
    opacity: 1;
    transform: translateY(0);
}

.dropdown-v2__triangle.right {
    margin-right: calc(40% - 8px);
}

.dropdown-v2__triangle.topcenter {
    margin: 0;
    left: 50%;
    top: -6px;
    transform: scaleY(-1) translate(-50%, -100%);
}

.dropdown-v2-button {
    @apply border border-solid border-grey-100 bg-grey-100 rounded-lg px-4 text-grey-700 h-12 flex items-center whitespace-nowrap cursor-pointer;
    overflow: hidden;
}

.dropdown-v2-button:hover {
    @apply bg-grey-200;
}

.dropdown-v2-button-small {
    @apply px-3 h-10;
}

.dropdown-v2-button-extra-small {
    @apply px-2 h-6;
}

.dropdown-v2-button-big {
    @apply h-14;
}

.dropdown-v2-button.whiteName {
    @apply text-white;
}

.dropdown-v2-button.semi-transparent {
    @apply text-white;
    background-color: rgb(255 255 255 / .1);
    border-color: rgb(255 255 255 / .0);
}

.dropdown-v2-button.transparent {
    background: transparent;
    border-color: transparent;
}

.dropdown-v2-button.dark {
    @apply border-grey-250 bg-grey-250;
}

.dropdown-v2-button.transparent:hover {
    @apply bg-transparent;
}

.dropdown-v2-button.transparent.menuOpened {
    @apply bg-transparent;
}

.dropdown-v2-button.secondary {
    @apply border border-2 border-blue-400 text-blue-400;
    background: transparent;
}

.dropdown-v2-button.accordion {
    @apply justify-center;
}

.dropdown-v2-button.noMargins {
    margin: 0;
    padding: 0;
}

.dropdown-v2-menu {
    @apply top-12 z-50;
    max-height: 200px;
    transition: all ease-in-out .1s;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    transform: translateY(-5px);
}

.dropdown-v2-menu__active {
    opacity: 1;
    height: auto;
    pointer-events: auto;
    transform: translateY(0);
}

.dropdown-v2-menu.right {
    @apply rounded px-5 py-3 shadow-lg absolute right-0;
}

.dropdown-v2-menu.left {
    @apply rounded px-5 py-3 shadow-lg absolute left-0;
}

.dropdown-v2-menu.topcenter {
    @apply rounded px-5 py-3 shadow-lg absolute top-0;
    right: 50%;
    transform: translate(50%, -100%);
}

.dropdown-v2-menu.accordion {
    @apply bg-transparent shadow-none flex flex-col items-center;
    max-height: auto;
    overflow-y: visible;
    transition: none;
}

.accordion.dropdown-v2-menu__active {
    position: static;
}

.dropdown-v2-menu.transparent.accordion {
    @apply text-white;
}

.dropdown-v2-item {
    @apply py-2 cursor-pointer;
}

.dropdown-v2-item-disabled {
    @apply py-2 cursor-not-allowed;
}

.separator {
    @apply ml-2 text-grey-300;
}

.separator.semi-transparent {
    color: rgb(255 255 255 / .3);
}

.dropdown-v2-icon {
    @apply text-xs;
    transition: all ease-in-out .2s;
}

.dropdown-v2-icon.bigIcon {
    @apply text-base;
}

.dropdown-v2-icon--upper {
    margin-top: -2px;
}

.dropdown-v2-icon__active {
    transform: rotate(180deg);
}
</style>
