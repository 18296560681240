<script setup>
import { computed } from 'vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import CodaDownloadButtons from '@/components/CodaDownloadButtons.vue';

const emits = defineEmits(['hasClickedSignAction']);

const props = defineProps({
    mandate: {
        type: Object,
        required: true,
    },
    clientV2: {
        type: Object,
        required: true,
    },
    close: {
        type: Function,
        required: true,
    },
});

const isTTMOnly = computed(() => {
    return props.mandate.bankAccounts.filter(ba => ba.state !== 'archived').every(ba => ba.flowState === 'draft-active-bank-mandate');
});
const digitalSignatureURL = computed(() => props.mandate.twikeyUrl ? props.mandate.twikeyUrl : props.mandate.signHerePackage.packageSignUrl);

const mandateType = computed(() => {
    if (isTTMOnly.value) {
        return 'digital';
    }

    switch (props.mandate.bank.slug) {
        case 'kbc':
        case 'cbc':
            return 'kcbc';
        case 'delta-lloyd':
            return 'paper';
    }
    return 'digital';
});
</script>

<template>
    <div class='ml-5'>
        <h1>
            {{ $t('sign-coda.title') }}
        </h1>
        <template v-if='mandateType === "digital"'>
            <p>
                {{ $t('sign-coda.digital.intro', {bank_name: mandate.bank.name}) }}
            </p>

            <div class='button-centered'>
                <a :href='digitalSignatureURL' target='_blank'>
                    <FriendlyButton
                        square
                        symbol='pencil-square-o'
                        extra-small
                        secondary
                        no-margin
                        :action='() => emits("hasClickedSignAction")'
                    >
                        {{ $t('sign-coda.digital.button') }}
                    </FriendlyButton>
                </a>
            </div>
        </template>
        <template v-if='mandateType === "kcbc"'>
            <p>{{ $t('sign-coda.kcbc.intro', {bank_name: mandate.bank.name}) }}</p>

            <h2>{{ $t('sign-coda.step-1') }}</h2>
            <p>
                {{ $t('sign-coda.kcbc.step-1') }}
            </p>
            <div class='button-centered'>
                <a :href='digitalSignatureURL' target='_blank'>
                    <FriendlyButton
                        symbol='pencil-square-o'
                        square
                        secondary
                        extra-small
                        no-margin
                        :action='() => emits("hasClickedSignAction")'
                    >
                        {{ $t('sign-coda.kcbc.step-1-button') }}
                    </FriendlyButton>
                </a>
            </div>

            <h2>{{ $t('sign-coda.step-2') }}</h2>

            <p>
                {{ $t('sign-coda.kcbc.step-2-1') }}
            </p>
            <p class='ml-2'>
                <ul>
                    <li v-for='bankAccount in mandate.bankAccounts'>
                        {{ bankAccount.iban }}
                    </li>
                </ul>
            </p>

            <p>
                {{ $t('sign-coda.kcbc.step-2-2') }}
            </p>
            <div class='button-centered'>
                <a :href='$t("sign-coda.kcbc-activation-link")' target='_blank'>
                    <FriendlyButton
                        square
                        extra-small
                        no-margin
                        secondary
                        symbol='external-link'
                    >
                        {{ $t('sign-coda.kcbc.step-2-button') }}
                    </FriendlyButton>
                </a>
            </div>
        </template>
        <template v-if='mandateType === "paper"'>
            <p>{{ $t('sign-coda.paper.intro', {bank_name: mandate.bank.name}) }}</p>

            <h2>{{ $t('sign-coda.step-1') }}</h2>
            <p>{{ $t('sign-coda.paper.step-1') }}</p>
            <div class='button-centered'>
                <CodaDownloadButtons :mandate='mandate' show-in-popup />
            </div>

            <h2>{{ $t('sign-coda.step-2') }}</h2>
            <p class='mb-6'>
                {{ $t('sign-coda.paper.step-2') }}
            </p>

            <h2>{{ $t('sign-coda.step-3') }}</h2>
            <p>{{ $t('sign-coda.paper.step-3') }} <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>. </p>
        </template>
        <div class='text-center mt-5'>
            <FriendlyButton
                label='btn-close'
                :action='close'
                symbol='times'
                extra-small
                square
            />
        </div>
    </div>
</template>

<style scoped>
h1 {
    @apply flex mb-5 text-4xl mt-4
}

h2 {
    @apply flex mb-2 text-2xl mt-2
}

.button-centered {
    @apply mt-3 mb-6 text-center
}
</style>
