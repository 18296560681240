<script>
import FormTextarea from '@/components/FormTextarea';
import {gql} from '@apollo/client/core';
import notify from '@/notify';
import Popup from '@/clientcomponents/Popup';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import {datadogRum} from '@datadog/browser-rum';
import FormRadio from '@/components/FormRadio';
import FormCheckbox from '@/components/FormCheckbox';
import { Form } from 'vee-validate';

export default {
    name: 'ArchiveClientPopup',
    props: {
        clientId: String,
        show: Boolean,
    },
    components: {
        FormTextarea,
        Popup,
        FriendlyButton,
        FormRadio,
        FormCheckbox,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
    },
    data () {
        return {
            archiveClientPopup: false,
            archiveClientPopupWhy: '',
            archiveClientPopupWhyFreeNote: '',
            confirmClientArchival: false,
        };
    },
    emits: ['close'],
    methods: {
        closeArchiveClientPopup () {
            // log the closing of the popup
            datadogRum.addAction('clientArchivePopupClose');
            this.$emit('close');
        },
        async archiveClient (values) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation ArchiveClient ($clientId: String!, $reason: ClientArchiveReason!, $reasonText: String) {
                        archiveClient(clientId: $clientId, reason: $reason, reasonText: $reasonText) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        clientId: this.clientId,
                        reason: values.reason,
                        reasonText: values.reasonText,
                    },
                });
            } catch {
                notify.error(this.$t('err-server-fail'));
            }

            notify.success(this.$t('lbl-archive-client-success'));
            this.$router.push({name: 'fidu-client-search'});
        },
        canArchiveClient (values) {
            return (
                values.reason &&
                (
                    values.reason !== 'archiveReasonOther' ||
                    values.reasonText
                ) &&
                values.confirm
            );
        },
    },
};
</script>

<template>
    <Form @submit='archiveClient' v-slot='{values}'>
        <Popup
            :show='show'
            :close='closeArchiveClientPopup'
            small
            vid='client-archive-popup'
        >
            <template #header>
                {{ $t('ttl-client-archive') }}
            </template>
            <p>
                {{ $t('p-client-archive-1') }}
            </p>

            <p>
                <strong>
                    {{ $t('p-client-archive-2') }}
                </strong>
            </p>

            <div class='mt-6' id='client-archive-popup-reasons'>
                <span class='block mb-2'>
                    <FormRadio
                        name='reason'
                        value='archiveReasonClientStopActivity'
                        id='decline'
                    >
                        {{ $t('p-client-archive-reason-1') }}
                    </FormRadio>
                </span>
                <span class='block mb-2'>
                    <FormRadio
                        name='reason'
                        value='archiveReasonSwitchAccountant'
                        id='decline'
                    >
                        {{ $t('p-client-archive-reason-2') }}
                    </FormRadio>
                </span>
                <span class='block mb-2'>
                    <FormRadio
                        name='reason'
                        value='archiveReasonClientStopCodabox'
                        id='decline'
                    >
                        {{ $t('p-client-archive-reason-3') }}
                    </FormRadio>
                </span>
                <span class='block mb-2'>
                    <FormRadio
                        name='reason'
                        value='archiveReasonCost'
                        id='decline'
                    >
                        {{ $t('p-client-archive-reason-4') }}
                    </FormRadio>
                </span>
                <span class='block mb-2'>
                    <FormRadio
                        name='reason'
                        value='archiveReasonOther'
                        id='decline'
                    >
                        {{ $t('p-client-archive-reason-5') }}
                    </FormRadio>
                </span>
                <span class='block mt-3'>
                    <FormTextarea
                        name='reasonText'
                        :placeholder='$t("lbl-archive-client-reason-other")'
                        v-if='values.reason === "archiveReasonOther"'
                        edit
                        rules='required|max:255'
                        mode='aggressive'
                    />
                </span>
            </div>

            <div class='mt-5'>
                <FormCheckbox
                    name='confirm'
                    id='client-archive-popup-confirmation-check'
                    :label='$t("lbl-confirm-archive")'
                    edit
                />
            </div>

            <template #buttons>
                <div class='flex'>
                    <FriendlyButton
                        label='btn-cancel'
                        class='mr-3 ml-auto'
                        :action='closeArchiveClientPopup'
                        symbol='times'
                        small
                        no-margin
                        secondary
                        id='client-archive-popup-cancel'
                        datadog-action='clientArchivePopupCancel'
                    />
                    <FriendlyButton
                        label='btn-action-archive-client'
                        small
                        no-margin
                        symbol='check'
                        :disabled='!canArchiveClient(values)'
                        id='client-archive-popup-do-archive'
                        datadog-action='clientArchivePopupSubmit'
                        type='submit'
                    />
                </div>
            </template>
        </Popup>
    </Form>
</template>
