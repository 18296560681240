<script setup>

import { ref, watch } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import ProductStatesChart from '@/fiduciary/modules/ProductStatesChart.vue';
import router from '@/router';

const props = defineProps({
    currentEnvironment: {
        type: Object,
        required: true,
    },
});

const products = ['CODA', 'SODA', 'VOILA', 'CARO'];
const series = ref([]);
const loading = ref(true);

setProductStates();

watch(() => props.currentEnvironment.id, () => {
    setProductStates();
});

async function setProductStates () {
    loading.value = true;
    try {
        series.value = await getProductStates();
    } catch {
        series.value = [];
    }
    loading.value = false;
}

async function getProductStates () {
    return new Promise((resolve, reject) => {
        const { result, onResult, onError } = useQuery(
            gql`query GetProductStates($fiduciaryId: String) {
                productStates(fiduciaryId:$fiduciaryId) {
                    timestamp
                    coda { active {count ids} inactive {count ids} }
                    soda { active {count ids} inactive {count ids}}
                    voila { active {count ids} inactive {count ids}}
                    caro { active {count ids} inactive {count ids}}
                }
            }`,
            {
                fiduciaryId: props.currentEnvironment.id,
            },
        );
        onResult(() => {
            const res = result.value.productStates;
            resolve([
                {
                    name: 'active',
                    data: [res.coda.active.count, res.soda.active.count, res.voila.active.count, res.caro.active.count],
                },
                {
                    name: 'inactive',
                    data: [res.coda.inactive.count, res.soda.inactive.count, res.voila.inactive.count, res.caro.inactive.count],
                },
            ]);
        });

        onError((error) => {
            reject(error);
        });
    });
}

function toCompanyInsights (product, status) {
    router.push({name: 'company-insights', query: {product: product, status: status}});
}
</script>

<template>
    <ProductStatesChart
        :products='products'
        :series='series'
        :loading='loading'
        @clicked='toCompanyInsights'
    />
</template>
